import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';
import { Section } from '../../../../../../apps/admin/src/app/classes/section';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { BaseSectionComponent } from '../_base-section/base-section.component';
import { NewsletterSectionConfig } from './newsletter-section-config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'section[it-newsletter-section].it-section',
  templateUrl: './newsletter-section.component.html'
})
export class NewsletterSectionComponent extends BaseSectionComponent implements OnInit {
  @Input() preview: boolean = false;
  @Input() override section: Section;
  @Output() override sectionChanged = new EventEmitter();
  configuration: NewsletterSectionConfig = new NewsletterSectionConfig();

  visibility: string = '';
  @HostBinding(`class`) get t(){
    return this.visibility;
  };

  constructor(
    public override modalService: ModalService,
    public override sectionService: SectionService,
    public override toastService: ITToastService
  ) {
    super(
      modalService,
      sectionService,
      toastService
    );
  }

  override ngOnInit() {
    this.configuration = new NewsletterSectionConfig(JSON.parse(this.section.data));
    if(!this.preview) {
      this.visibility = this.section.visibility;
    }
  }
}
