import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITDropdownComponent } from './dropdown.component';
import { ITDropdownMenuComponent } from './dropdown-menu.component';
import { ITOffClickModule } from '../off-click/off-click.module';



@NgModule({
  declarations: [
    ITDropdownComponent,
    ITDropdownMenuComponent
  ],
  imports: [
    CommonModule,
    ITOffClickModule
  ],
  exports: [
    ITDropdownComponent,
    ITDropdownMenuComponent
  ],
  providers: [
  ]
})
export class ITDropdownModule { }
