<div class="it-section__edit-actions" *ngIf="preview">
  <div class="action" (click)="update()">
    <span class="it-icon settings"></span>
    <span>Configure section</span>
  </div>
  <div class="spacer"></div>
  <div class="action double">
    <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
    <span class="it-icon arrow-down" (click)="sectionChangeOrder('down')"></span>
  </div>
  <div class="action delete" (click)="remove()">
    <span class="it-icon delete"></span>
  </div>
</div>

<div class="it-section__content-wrapper {{ section.custom_class }}">
  <div class="it-section__content category-list desktop" [itLoading]="showLoading" [backgroundColor]="'#fff'">
    <div class="it-section__item"
      *ngFor="let category of categories; let i = index"
      [class.nudge-left]="categories.length >= 4 && [0,1].includes(i)" 
      [class.nudge-right]="categories.length >= 4 && [2,3].includes(i)" 
    >
      <div class="left">
        <h1>{{category.title}}</h1>
        <p>{{category.description}}</p>
        <it-button [routerLink]="!preview ? ['/product/category', category.slug] : []">Vidi proizvode</it-button>
      </div>
      <div class="right">
        <img itImageHandler [apiRoot]="apiRoot" [imagePath]="category.image_url || ''" [imageSize]="'large'">
      </div>
    </div>
  </div>
  <div class="it-section__content category-list mobile" [itLoading]="showLoading" [backgroundColor]="'#fff'">
      <ng-container *ngFor="let category of categories">
        <div class="it-section__item">
          <img itImageHandler [apiRoot]="apiRoot" [imagePath]="category.image_url">
          <h1>{{category.title}}</h1>
          <p>{{category.description}}</p>
          <it-button [routerLink]="!preview ? ['/product/category', category.slug] : []">Vidi proizvode</it-button>
        </div>
      </ng-container>
  </div>
</div>
