import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { ITPageSectionsDirective } from '../../directives/page-sections/page-sections.directive';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';
import { SectionPanelComponent } from '../../modals/section-panel/section-panel.component';

@Component({
  selector: 'it-base-section',
  templateUrl: './base-section.component.html'
})
export class BaseSectionComponent implements OnInit {
  @ViewChild(ITPageSectionsDirective) pageSectionsWrapper: ITPageSectionsDirective;

  @Input() section: any;
  @Output() sectionChanged = new EventEmitter();
  constructor(
    public modalService: ModalService,
    public sectionService: SectionService,
    public toastService: ITToastService
  ) { }

  ngOnInit() {
  }

  update() {
    const modalRef = this.modalService.open(SectionPanelComponent);
    if(this.section.sectionable_type === 'App\\Models\\Page') {
      modalRef.componentInstance.ownerType = 'pages';
    } else {
      modalRef.componentInstance.ownerType = 'products';
    }
    modalRef.componentInstance.section = this.section;
    modalRef.componentInstance.ownerId = this.section.sectionable_id;
    modalRef.componentInstance.sectionType = this.section.type;
    modalRef.result
      .then((result) => {
        if(result) {
          this.sectionChanged.emit(result);
        }
      })
      .catch(() => {})
  }

  remove() {
    this.modalService.confirm({
      title: 'Delete',
      body: 'Are you sure you want to delete this section? This action can’t be undone.',
      type: 'danger',
      buttons: [
        {
          role: 'cancel',
          text: 'Cancel'
        },
        {
          text: 'Delete',
          handler: () => {
            let ownerType = 'products';
            if(this.section.sectionable_type === 'App\\Models\\Page') {
              ownerType = 'pages';
            }
            this.sectionService.deleteSection(ownerType, this.section.sectionable_id, this.section.id)
              .subscribe({
                next: () => {
                  this.toastService.show({
                    type: 'success',
                    message: 'Section has been successfully deleted.'
                  })
                  this.sectionChanged.emit('changed');
                },
                error: (error) => {
                  this.modalService.error({
                    errors: error
                  })
                }
              })
          }
        }
      ]
    });
  }

  sectionChangeOrder(type: string) {
    let ownerType = 'product';
    let order = this.section.order;

    if(this.section.sectionable_type === 'App\\Models\\Page') {
      ownerType= 'pages';
    }else {
      ownerType = 'products';
    }

    if(type === 'up') {
      order = --order
    }else {
      order = ++order
    }

    this.sectionService.changeOrder(ownerType, this.section.sectionable_id, this.section.id, {order: order})
      .subscribe({
        next: () => {
          this.sectionChanged.emit('changed');
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
        }
      })
  }

}
