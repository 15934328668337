<nav class="it-navigation">
  <div class="it-logo" (click)="navigate(homePage)"></div>
  <ul class="it-navigation__items">
    <li 
      class="main-navigation-item" 
      *ngFor="let item of mainNavigation" 
      [id]="item?.title" 
      (mouseenter)="item.subitems?.length > 0 ? toggleDropdown(item, true) : ''"
      (mouseleave)="item.subitems?.length > 0 ? toggleDropdown(item, false) : ''"
    >
      <span (click)="item.subitems?.length > 0 ? '' : navigate(item)">{{item.title}}</span>
      <ul class="navigation-dropdown"  [id]="item.slug" *ngIf="item.subitems?.length > 0">
        <ul class="navigation-dropdown__list" *ngFor="let subitem of item.subitems">
          <li class="list-item__title" (click)="subitem.subsubitems?.length > 0 ? '' : navigate(subitem)">{{subitem.title}}</li>
          <ul class="list-items" *ngIf="subitem.subsubitems?.length > 0">
            <li *ngFor="let subsubitem of subitem.subsubitems" (click)="navigate(subsubitem)">{{subsubitem.title}}</li>
          </ul>
        </ul>
        <a class="promo-wrapper it-clickable" [routerLink]="['/product', item.featured_product.slug]" *ngIf="item.featured_product">
          <img *ngIf="item.featured_product.image_urls"
            itImageHandler
            [apiRoot]="apiRoot"
            [imageSize]="'medium'"
            [imagePath]="item.featured_product.image_urls[0]"
          >
          <h2 class="promo-description">{{ item.featured_product.name }}</h2>
          <p class="promo-description">{{ item.featured_product.description }}</p>            
        </a>
      </ul>
    </li>
  </ul>
  <div class="navigation-actions" *ngIf="!showSearchInput">
    <span class="it-icon users" [routerLink]="['/profile']"></span>
    <span class="it-icon search" (click)="showSearch()"></span>
  </div>
  <form action=".">
    <it-input 
      itOffClick (offClick)="showSearch()" 
      *ngIf="showSearchInput" 
      type="search" placeholder="Pretraži proizvode..."
      [(ngModel)]="searchTerm"
      [ngModelOptions]="{ standalone: true }"
      (keyup.enter)="search()"
    ></it-input>    
  </form>
</nav>


<nav class="it-navigation__mobile">
  <div class="it-icon" [class.menu]="!showNavigation" [class.menu-close]="showNavigation" (click)="toggleNavigation()"></div>
  <div class="it-logo" (click)="navigate(homePage)"></div>
  <span class="it-icon search" (click)="showResponsiveSearch()"></span>
  <it-input 
    *ngIf="showResponsiveSearchInput" 
    itOffClick 
    type="search" 
    placeholder="Pretraži proizvode..." 
    [(ngModel)]="searchTerm"
    [ngModelOptions]="{ standalone: true }"
    (keyup.enter)="search()" 
    (searchTerm)="search()"
  ></it-input>
  <div class="navigation-wrapper" [class.show]="showNavigation" [class.top]="showResponsiveSearchInput">
    <ul>
      <li class="nav-item login"> <span class="title"><span class="it-icon users"></span> Ulogujte se</span></li>
      <li class="nav-item" *ngFor="let item of mainNavigation" (click)="item?.subitems.length > 0 ? toggleSubnavigation($event, item?.id) : ''">
        <span class="title">{{item.title}} <span class="it-icon" [class.add]="selectedNavItems.includes(item?.id) ? false : true" [class.minus]="selectedNavItems.includes(item?.id) ? true : false"></span></span>
        <ul class="nav-sub__items" *ngIf="item?.subitems.length > 0" [id]="item?.id">
          <li class="nav-item sub" *ngFor="let subitem of item.subitems" (click)="subitem?.subsubitems.length > 0 ? toggleSubnavigation($event, subitem.id) : ''">
            <span class="title">{{subitem.title}} <span class="it-icon" [class.add]="selectedNavItems.includes(subitem?.id) ? false : true" [class.minus]="selectedNavItems.includes(subitem?.id) ? true : false"></span></span>
            <ul class="nav-sub-sub__items" *ngIf="subitem?.subsubitems.length > 0" [id]="subitem?.id">
              <li *ngFor="let subsubitem of subitem.subsubitems" (click)="navigate(subsubitem); toggleNavigation();">{{subsubitem?.title}}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>

