<div class="it-section__edit-actions" *ngIf="preview">
  <div class="action" (click)="update()">
    <span class="it-icon settings"></span>
    <span>Configure section</span>
  </div>
  <div class="spacer"></div>
  <div class="action double">
    <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
    <span
      class="it-icon arrow-down"
      (click)="sectionChangeOrder('down')"
    ></span>
  </div>
  <div class="action delete" (click)="remove()">
    <span class="it-icon delete"></span>
  </div>
</div>

<div
  class="it-section__content-wrapper {{ section.custom_class }}"
  [itLoading]="showLoading"
>
  <h1 class="section-title" *ngIf="section.title">{{ section.title }}</h1>
  <div
    class="it-section__content product-list"
    *ngIf="products && products.length > 0"
  >
    <it-slides [slidesBreakpoints]="slidesBreakpoints">
      <it-slide
        *ngFor="let product of products"
        [routerLink]="!preview ? ['/product', product.slug] : []"
      >
        <div class="it-section__item">
          <ng-container *ngIf="product.image_urls">
            <img
              itImageHandler
              [apiRoot]="apiRoot"
              [imagePath]="product.image_urls[0]"
            />
          </ng-container>
          <div class="info">
            <span class="product-title">{{ product?.name }}</span>
            <span class="price" [class.discount]="product?.price_discounted"
              >{{
                product?.price_discounted
                  ? (product?.price_discounted | number: '1.2-2')
                  : (product?.price | number: '1.2-2')
              }}
              RSD</span
            >
            <span class="old-price" *ngIf="product?.price_discounted"
              >{{ product?.price | number: '1.2-2' }} RSD</span
            >
          </div>
        </div>
      </it-slide>
    </it-slides>
    <div class="see-all">
      <it-button color="outline" (click)="seeAllProducts()"
        >Vidi proizvode</it-button
      >
    </div>
  </div>
</div>
