import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ITPaginationComponent } from './pagination.component';
import { ITSelectModule } from '../../components/select/select.module';



@NgModule({
  declarations: [
    ITPaginationComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ITSelectModule
    ],
  exports: [
    ITPaginationComponent
  ]
})
export class ITPaginationModule { }
