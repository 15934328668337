
<div class="website-root">
  <header>
    <it-navigation></it-navigation>
  </header>
  <main [class]="pageClass" (scroll)="onScroll($event)" itInfiniteScroll (scrolled)="scrolledToBottom('bottom')">
    <router-outlet></router-outlet>
    <it-footer *ngIf="showFooter"></it-footer>
  </main>

  <it-button class="compare" *ngIf="compareProducts.length > 0 && !showCompareBubble" (click)="toggleCompareBubble()">Uporedi proizvode ({{compareProducts.length}})</it-button>
  <div class="compare-bubble" [class.show]="showCompareBubble">
    <div class="header">
      <h4>Poređenje proizvoda prema kategoriji</h4>
    </div>
    <div class="body">
      <div class="product-category__wrapper" *ngFor="let type of compareProductsShow">
        <div class="product-category">
          <h4>{{type.name}}</h4>
          <div class="product" *ngFor="let product of type.products">
            <div class="content">
              <img
              itImageHandler
              [apiRoot]="fileRoot"
              [imagePath]="product.image"
              imageSize='small'>
            <span>{{product.name}}</span>
            </div>
            <div class="it-icon menu-close it-clickable" (click)="deleteCompareProduct(product.id)"></div>
        </div>
        <it-button (click)="compare(type.product_type_id)">Uporedi</it-button>
      </div>
    </div>

    </div>
    <div class="footer">
      <p (click)="toggleCompareBubble()">Zatvori</p>
    </div>
  </div>

  <div
    class="scroll-to-top" 
    [class.show]="showResponsiveNav"
    (click)="scrollToTop()"
  >
    <span class="it-icon arrow-up"></span>
  </div>

</div>
