import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NewsletterSectionConfig } from './newsletter-section-config';

@Component({
  selector: 'it-newsletter-section-configuration',
  templateUrl: './newsletter-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewsletterSectionConfigurationComponent),
      multi: true
    }
  ]
})
export class NewsletterSectionConfigurationComponent implements OnInit {
  @Input() value: NewsletterSectionConfig = new NewsletterSectionConfig;
  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() {
  }

  writeValue(value: any): void {
    this.value = new NewsletterSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

}
