import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITButtonComponent } from './button.component';



@NgModule({
  declarations: [
    ITButtonComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ITButtonComponent
  ]
})
export class ITButtonModule { }
