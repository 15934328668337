<div class="it-row">
  <div class="it-col">
    <p class="it-bold">Slides</p>
  </div>
</div>
<div class="it-row">
  <div class="it-col">
    <it-input
      [label]="'Number of columns'"
      [(ngModel)]="value.columns"
      (ngModelChange)="onChange(value)"
    ></it-input>
  </div>
</div>
<ng-container *ngFor="let slide of value.slides">
  <div class="it-row space-16 it-slide__container">
    <span class="action-remove it-icon delete" (click)="removeSlide(slide)"></span>
    <div class="it-col md-4">
      <div class="it-row">
        <div class="it-col mt-0">
          <it-image
            label="Add image"
            [crop]="false"
            [(ngModel)]="slide.imageUrl"
            (ngModelChange)="onChange(value)"
          ></it-image>
        </div>
      </div>
    </div>
    <div class="it-col md-8">
      <div class="it-row">
        <div class="it-col mt-0">
          <it-input
            type="text"
            label="Title"
            [(ngModel)]="slide.title"
            (ngModelChange)="onChange(value)"
          ></it-input>
        </div>
        <div class="it-col">
          <it-input
            label="Description"
            [type]="'textarea'"
            [(ngModel)]="slide.description"
            (ngModelChange)="onChange(value)"
          ></it-input>
        </div>
        <div class="it-col">
          <it-input
            type="text"
            label="Link URL"
            [(ngModel)]="slide.linkUrl"
            (ngModelChange)="onChange(value)"
          ></it-input>
        </div>
        <div class="it-col">
          <it-checkbox
            label="Dark text"
            [(ngModel)]="slide.dark"
            (ngModelChange)="onChange(value)"
          ></it-checkbox>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="it-row">
  <div class="it-col">
    <it-button [size]="'small'" (click)="addSlide()">Add slide</it-button>
  </div>
</div>
