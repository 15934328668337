import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITSelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { ITOffClickModule } from '../../components/off-click/off-click.module';
import { ITInfiniteScrollModule } from '../../components/infinite-scroll/infinite-scroll.module';



@NgModule({
  declarations: [
    ITSelectComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ITOffClickModule,
        ITInfiniteScrollModule
    ],
  exports: [
    ITSelectComponent
  ]
})
export class ITSelectModule { }
