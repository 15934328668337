import { Directive, ElementRef, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControlName, NgModel } from '@angular/forms';

@Directive({
  selector: '[itInputDirective]'
})
export class ITInputDirective implements OnInit, OnDestroy {

  valueSub: Subscription;

  constructor(
      private element: ElementRef,
      @Optional() private formControlName: FormControlName,
      @Optional() private model: NgModel
  ) {

  }

  ngOnInit() {
    if (this.formControlName) {
      this.valueSub = this.formControlName.valueChanges.subscribe(value => {
        this.element.nativeElement.classList.toggle('dc-touched', value);
      });

      if (this.formControlName.value) {
        this.element.nativeElement.classList.toggle('dc-touched');
      }
    }

    if (this.model) {
      this.valueSub = this.model.control.valueChanges.subscribe(value => {
        this.element.nativeElement.classList.toggle('dc-touched', value);
      });

      if (this.model.model) {
        this.element.nativeElement.classList.toggle('dc-touched');
      }
    }
  }

  ngOnDestroy() {
    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }
}
