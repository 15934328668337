<div class="it-row">
  <div class="it-col">
    <p class="it-bold">Promo</p>
  </div>
</div>
<div class="it-row">
  <div class="it-col">
    <it-image
      [label]="'Image'"
      [crop]="false"
      [(ngModel)]="value.imageUrl"
      (ngModelChange)="onChange(value)"
    ></it-image>
  </div>
  <div class="it-col">
    <it-text-editor
      class="it-section-editor"
      [(ngModel)]="value.content"
      (ngModelChange)="onChange(value)"
    ></it-text-editor>
  </div>
  <div class="it-col">
    <it-select
      [label]="'Position'"
      [options]="positionOptions"
      [(ngModel)]="value.position"
      (ngModelChange)="onChange(value)"
    ></it-select>
  </div>
</div>