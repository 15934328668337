import { map, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { GlobalService } from "../services/global.service";
import { environment } from '../../environments/environment';

@Injectable()
export class WebsiteResolver implements Resolve<any> {

  constructor(
    private router: Router,
    private globalService: GlobalService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.globalService
      .getWebsite(environment.websiteId, { include: 'navigations.navigation_items.entity,navigations.navigation_items.featured_product' })
      .pipe(map(response => { 
        return response.data;
      }));
  }

}
