import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../../apps/admin/src/environments/environment';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';
import { Product } from '../../../../../../apps/admin/src/app/classes/product';
import { Section } from '../../../../../../apps/admin/src/app/classes/section';
import { ProductService } from '../../../../../../apps/admin/src/app/services/product.service';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { BaseSectionComponent } from '../_base-section/base-section.component';
import { ProductListSectionConfig } from './product-list-section-config';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'section[it-product-list-section].it-section',
  templateUrl: './product-list-section.component.html'
})
export class ProductListSectionComponent extends BaseSectionComponent implements OnInit {
  @Input() preview: boolean = false;
  @Input() override section: Section;
  @Output() override sectionChanged = new EventEmitter();
  configuration: ProductListSectionConfig = new ProductListSectionConfig();

  visibility: string = '';
  @HostBinding(`class`) get t() {
    return this.visibility;
  };

  products: Product[];
  slidesBreakpoints = {
    320: {
      slidesPerView: 1,
      spaceBetween: 32
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 32
    },
    900: {
      slidesPerView: 3,
      spaceBetween: 32
    },
    1440: {
      slidesPerView: 4,
      spaceBetween: 32
    }
  };

  apiRoot = environment.apiRoot;
  showLoading: boolean = false;
  constructor(
    public override modalService: ModalService,
    public override sectionService: SectionService,
    public override toastService: ITToastService,
    private productService: ProductService,
    private router: Router,
  ) {
    super(
      modalService,
      sectionService,
      toastService
    );
  }

  override ngOnInit() {
    this.configuration = new ProductListSectionConfig(this.section.data);
    if (!this.preview) {
      this.visibility = this.section.visibility;
    }
    this.getProducts();
  }

  getProducts() {
    this.showLoading = true;
    const params: any = {
      current_page: 1,
      per_page: this.configuration.limit
    }
    if (this.configuration.new) {
      params.product_new = true;
    }
    if (this.configuration.discounted) {
      params.discounted = true;
    }
    if (this.configuration.on_promotion) {
      params.on_promotion = true;
    }
    if (this.configuration.product_category_ids) {
      params['product_category_ids[]'] = this.configuration.product_category_ids;
    }
    this.productService.filterProducts(params)
      .subscribe({
        next: (response) => {
          this.products = response.data;
          this.showLoading = false;
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
          this.showLoading = false;
        }
      });
  }

  seeAllProducts() {
    const params: any = {};
    if (this.configuration.new) {
      params.product_new = true;
    }
    if (this.configuration.discounted) {
      params.discounted = true;
    }
    if (this.configuration.on_promotion) {
      params.on_promotion = true;
    }
    if (this.configuration.product_category_ids) {
      params['product_category_ids[]'] = this.configuration.product_category_ids;
    }

    this.router.navigate(['/product', 'search'], { queryParams: params });
  }

}
