import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderSectionComponent } from './slider-section.component';
import { SliderSectionConfigurationComponent } from './slider-section-configuration.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ITButtonModule } from '../../components/button/button.module';
import { ITSliderModule } from '../../components/carousel/slider.module';
import { ITInputModule } from '../../components/input/input.module';
import { ITLoadingModule } from '../../components/loading/loading.module';
import { ITImageModule } from '../../components/image/image.module';
import { ITImageHandlerModule } from '../../directives/image-handler/image-handler.module';
import { ITCheckboxModule } from '../../components/checkbox/checkbox.module';



@NgModule({
  declarations: [
    SliderSectionComponent,
    SliderSectionConfigurationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ITInputModule,
    ITButtonModule,
    ITImageModule,
    ITLoadingModule,
    ITImageHandlerModule,
    ITCheckboxModule,
    ITSliderModule
  ],
  exports: [
    SliderSectionComponent,
    SliderSectionConfigurationComponent
  ]
})
export class SliderSectionModule { }
