import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    private apiService: ApiService
  ) { }

  getWebsite(websiteId: string, params?: any) {
    return this.apiService.get(`websites/${websiteId}`, params);
  }

  getNavigations(params?: any) {
    return this.apiService.get(`navigation`, params);
  }

  getNavigation(slug: string, params?: any) {
    return this.apiService.get(`navigation/${slug}`, params);
  }

  getNavigationItem(navigationId: string, slug: string, params?: any) {
    return this.apiService.get(`navigation/${navigationId}/navigation_items/${slug}`, params);
  }

  // Product
  getProducts(params?: any) {
    return this.apiService.get(`products`, params);
  }

  filterProducts(params?: any) {
    return this.apiService.get(`products/filter`, params);
  }

  getProduct(slug: string, params?: any) {
    return this.apiService.get(`products/${slug}`, params);
  }

  getProductCategory(slug: string, params?: any) {
    return this.apiService.get(`product_categories/${slug}`, params);
  }

  getProductTypes(params?: any) {
    return this.apiService.get(`product_types`, params);
  }

  getProductCategoryFilters(productCategoryId, params?: any) {
    return this.apiService.get(`product_categories/${productCategoryId}`, params);
  }

  getProductType(slug: string, params?: any) {
    return this.apiService.get(`product_types/${slug}`, params);
  }

  getProductRelated(productSlug: string, params?: any) {
    return this.apiService.get(`products/${productSlug}/related`, params);
  }

  getProductAccessories(productSlug: string, params?: any) {
    return this.apiService.get(`products/${productSlug}/accessories`, params);
  }

  getProductGroups(productTypeId: string, params?: any) {
    return this.apiService.get(`product_types/${productTypeId}/product_property_groups`, params);
  }
}
