import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ITToastService } from '../../components/toast/toast.service';

@Component({
  selector: 'it-image',
  templateUrl: './image.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ITImageComponent),
    multi: true
  }]
})
export class ITImageComponent implements OnInit, ControlValueAccessor {

  @ViewChild('inputFile') inputFile;

  @Input() value: any;
  @Input() label: string;
  @Input() icon: string = 'image';
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() multiple: boolean = false;
  @Input() crop: boolean = true;
  @Input() size: 'default' | 'large' = 'default';
  @Input() maxSize: number;
  @Input() cropperAspectRation: number = 1/1;
  @Input() cropperWidth: number;
  @Input() cropperHeight: number;
  @Output() changeFile = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  readyToDrop: boolean = false;

  imageChangedEvent: any = '';
  imageBase64: any = '';
  croppedImage: any = '';
  showCropper: boolean = false;

  oldValue: any;

  constructor(
    private toastService: ITToastService
  ) { }

  ngOnInit() {
    if (this.multiple !== false) { this.multiple = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.required !== false) { this.required = true; }
  }

  writeValue(value: any) {
    this.value = value ? value : '';
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  emitChange(event) {
    if (this.maxSize) {
      if (this.maxSize < event.target.files[0].size) {
        this.toastService.show({
          type: 'error',
          message: 'Image is to large. Maximum size of image is ' + this.maxSize/1024 + 'kB.'
        })
        return;
      }
    }
    this.oldValue = this.value;
    const file = event.target.files[0];
    const reader = new FileReader();
    if (this.crop) {
      this.imageChangedEvent = event;
    }else {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.value = reader.result as string;
        if (this.onChange) {
          this.onChange(this.value);
        }
      }
      reader.onerror = () => {};
      this.changeFile.emit(this.value);
    }
  }

  delete() {
    this.value = null;
    this.inputFile.nativeElement.value = '';
    if (this.onChange) {
      this.onChange(this.value)
    }
  }

  onDrop(event: DragEvent) {
    if (this.maxSize) {
      if (this.maxSize > event.dataTransfer.files[0].size) {
        this.toastService.show({
          type: 'error',
          message: 'Image is to large. Maximum size of image is ' + this.maxSize/1024 + 'kB.'
        })
        return;
      }
    }
    this.oldValue = this.value;
    this.dragLeave(event);
    if (this.disabled) { return; }
    if (!this.multiple && event.dataTransfer.files.length > 1) {
      this.toastService.show({
        type: 'error',
        message: 'Maximum number of files for upload is 1'
      })
      return;
    }
    Array.from(event.dataTransfer.files).forEach((file: any) => {
      file.withCredentials = false;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (this.crop) {
          this.imageBase64 = reader.result as string;
        }else {
          this.value = reader.result as string;
          if (this.onChange) {
            this.onChange(this.value);
          }
        }
        reader.onerror = () => {};
        this.changeFile.emit(this.value);
        }
    });
  }

  choseFile(event) {
    if (this.disabled) { return; }
    this.inputFile.nativeElement.click();
  }

  dragEnter(event: DragEvent) {
    this.readyToDrop = true;
    event.preventDefault();
    event.stopPropagation();
  }

  dragLeave(event: DragEvent) {
    this.readyToDrop = false;
    event.preventDefault();
    event.stopPropagation();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(event) {
    this.showCropper = true;
  }

  closeCropper() {
    this.value = this.oldValue;
    if (this.onChange) { this.onChange(this.value); }
    this.showCropper = false;
    this.inputFile.nativeElement.value = '';
  }

  setCroppedImage() {
    this.value = this.croppedImage;
    if (this.onChange) { this.onChange(this.value); }
    this.showCropper = false;
    this.inputFile.nativeElement.value = '';
  }
}
