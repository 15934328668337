import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromoSectionComponent } from './promo-section.component';
import { PromoSectionConfigurationComponent } from './promo-section-configuration.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ITButtonModule } from '../../components/button/button.module';
import { ITInputModule } from '../../components/input/input.module';
import { ITLoadingModule } from '../../components/loading/loading.module';
import { ITImageModule } from '../../components/image/image.module';
import { ITImageHandlerModule } from '../../directives/image-handler/image-handler.module';
import { ITSelectModule } from '../../components/select/select.module';
import { ITTextEditorModule } from '../../components/text-editor/text-editor.module';



@NgModule({
  declarations: [
    PromoSectionComponent,
    PromoSectionConfigurationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ITInputModule,
    ITButtonModule,
    ITImageModule,
    ITLoadingModule,
    ITImageHandlerModule,
    ITTextEditorModule,
    ITSelectModule,
  ],
  exports: [
    PromoSectionComponent,
    PromoSectionConfigurationComponent
  ]
})
export class PromoSectionModule { }
