export class SliderSectionConfig {
  columns: number = 1;
  slides: { 
    title: string, 
    description: string,
    linkUrl: string, 
    dark: boolean,
    imageUrl: string,
  }[] = [];

  constructor(values: any = {}) {
    if (typeof values === 'string') {
      values = JSON.parse(values);
    } else {
      values = JSON.parse(JSON.stringify(values));
    }

    Object.assign(this, values);
  }
}
