<button
    class="{{color}} {{size}} {{icon}}"
    [ngClass]="
    {
    'active': active,
    'it-button': type === 'default',
    'it-icon': icon,
    'it-button__loading': loading,
    'only-icon': onlyIcon,
    'icon-right': iconPositionRight
    }"
    [disabled]="disabled"
>
  <span><ng-content></ng-content></span>
</button>
