<div class="it-section__edit-actions" *ngIf="!selectedContentId && preview">
  <div class="action" (click)="update()">
    <span class="it-icon settings"></span>
    <span>Configure section</span>
  </div>
  <div class="spacer"></div>
  <div class="action double">
    <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
    <span class="it-icon arrow-down" (click)="sectionChangeOrder('down')"></span>
  </div>
  <div class="action delete" (click)="remove()">
    <span class="it-icon delete"></span>
  </div>
</div>

<div class="it-section__content-wrapper {{ section.custom_class }}">
  <h1 *ngIf="section.title" class="section-title">{{ section.title }}</h1>
  <div class="it-section__content custom" [style]="'grid-template-columns: repeat('+ configuration.cols + ', 1fr)'" *ngIf="preview">
    <ng-container *ngFor="let content of configuration.contents">
      <div class="it-section__item" 
        [class.loading]="selectedContentId && content.id === loadingContentId"
        [class.active]="selectedContentId && content.id === selectedContentId">
        <ng-container *ngIf="!selectedContentId || content.id !== selectedContentId">
          <div style="height: 100%"
            [innerHtml]="content.textPreview"
            (click)="selectContent(content.id)"
          ></div>
        </ng-container>
        <ng-container *ngIf="selectedContentId && content.id === selectedContentId">
          <it-text-editor
            class="it-section-editor"
            [type]="'inline'"
            [(ngModel)]="content.text"
            itOffClick (offClick)="save(content.id)"
            [withBackDrop]="true"
          ></it-text-editor>
        </ng-container>
      </div>      
    </ng-container>
  </div>
  <div class="it-section__content custom" [style]="'grid-template-columns: repeat(' + configuration.cols + ', 1fr)'" *ngIf="!preview">
    <div *ngFor="let content of configuration.contents" [innerHtml]="content.textPreview"></div>
  </div>
</div>
