import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollSubject = new BehaviorSubject(null);
  scroll = this.scrollSubject.asObservable();

  constructor() { }

  getScrollOffset(scrollOffset) {
    this.scrollSubject.next(scrollOffset);
  }

}
