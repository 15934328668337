import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[itLoading]',
  templateUrl: './loading.component.html'
})
export class ITLoadingComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('itLoading') showLoading: boolean = false;
  @Input() backgroundColor: string;
  @Input() type: 'fixed' | 'fixed-height' | 'default' = 'default';

  constructor() { }

  ngOnInit() {
  }

}
