import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '../../components/modal/modal.service';
import { Menu } from '../../../../../../apps/admin/src/app/classes/menu';
import { PaginationConfig } from '../../../../../../apps/admin/src/app/classes/pagination-config';
import { MenuService } from '../../../../../../apps/admin/src/app/services/menu.service';
import { AllProductsSectionConfig } from './all-products-section-config';

@Component({
  selector: 'it-all-products-section-configuration',
  templateUrl: './all-products-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AllProductsSectionConfigurationComponent),
      multi: true
    }
  ]
})
export class AllProductsSectionConfigurationComponent implements OnInit {
  @Input() value: AllProductsSectionConfig = new AllProductsSectionConfig;
  menuOptions: Menu[] = [];
  menuPaginationConfig: PaginationConfig = {
    current_page: 1,
    per_page: 20,
    total: null
  };
  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor(
    private modalService: ModalService,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.getMenus();
  }

  writeValue(value: any): void {
    this.value = new AllProductsSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getMenus(append: boolean = false, searchTerm?: any) {
    if(append && this.menuPaginationConfig.total && this.menuOptions.length >= this.menuPaginationConfig.total) { return; }

    this.menuPaginationConfig.current_page = append ?  this.menuPaginationConfig.current_page + 1 : 1;

    const params: any = {
      current_page: this.menuPaginationConfig.current_page,
      per_page: this.menuPaginationConfig.per_page
    };

    if(searchTerm) {
      params.search_term = searchTerm;
    }

    this.menuService.getMenus(params)
      .subscribe({
        next: (response) => {
          if(append) {
            this.menuOptions = this.menuOptions.concat(response.data);
          }else {
            this.menuOptions = response.data;
            this.menuPaginationConfig.total = response.total;
          }
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          });
        }
      });
  }

}
