import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';
import { Section } from '../../../../../../apps/admin/src/app/classes/section';
import { MenuService } from '../../../../../../apps/admin/src/app/services/menu.service';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { BaseSectionComponent } from '../_base-section/base-section.component';
import { AllProductsSectionConfig } from './all-products-section-config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'section[it-all-products-section].it-section',
  templateUrl: './all-products-section.component.html'
})
export class AllProductsSectionComponent extends BaseSectionComponent implements OnInit {
  @Input() preview: boolean = false;
  @Input() override section: Section;
  @Output() override sectionChanged = new EventEmitter();
  configuration: AllProductsSectionConfig = new AllProductsSectionConfig();

  visibility: string = '';
  @HostBinding(`class`) get t(){
    return this.visibility;
  };

  navigationItems: any[];
  selectedNavItems: any [] = [];
  homePage: string;
  showNavigation: boolean = false;
  constructor(
    public override modalService: ModalService,
    public override sectionService: SectionService,
    public override toastService: ITToastService,
    private menuService: MenuService,
    public router: Router
  ) {
    super(
      modalService,
      sectionService,
      toastService
    );
  }

  override ngOnInit() {
    this.configuration = new AllProductsSectionConfig(JSON.parse(this.section.data));
    if(!this.preview) {
      this.visibility = this.section.visibility;
    }
    this.getMenu();
  }

  getMenu() {
    if(!this.preview) {
      this.menuService.getMenuItems(this.configuration.menuId)
      .subscribe({
        next: (response) => {
          const navigationItems = response.data;
          this.homePage = navigationItems.find(navItem => navItem.landing_page);
          navigationItems.sort((a, b) => { return a.order - b.order; });

          this.navigationItems = navigationItems.filter(navItem => !navItem.parent_id);
          if(this.navigationItems) {
            this.navigationItems.map(mainNavItem => {
              mainNavItem.subitems = navigationItems.filter(navItem => mainNavItem.id === navItem.parent_id);
              if(mainNavItem.subitems) {
                mainNavItem.subitems.map(subitem => {
                  subitem.subsubitems = navigationItems.filter(navItem => subitem.id === navItem.parent_id);
                })
              }
            })
          }
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
        }
      })
    }
  }

  toggleNavigation() {
    if(!this.preview) {
      this.showNavigation = !this.showNavigation;
    }
  }

  toggleSubnavigation(event, id) {
    event.stopPropagation();
    if(document.getElementById(id + '-section').classList.contains('show')) {
      document.getElementById(id + '-section').classList.remove('show');
      if(this.selectedNavItems.includes(id + '-section')) {
        this.selectedNavItems = this.selectedNavItems.filter((value) => {return value !== id})
      }
    } else {
      document.getElementById(id + '-section').classList.add('show');
      if(!this.selectedNavItems.includes(id + '-section')) {
        this.selectedNavItems.push(id + '-section')
      }
    }
  }

  navigate(navItem) {
    if(navItem.type === 'external_url') {
      window.open(navItem.data.external_url, '_blank');
    } else {
      if(navItem.type.includes('_')) {
        this.router.navigate([navItem.type.split('_').join('/'), navItem.slug]);
      }else {
        this.router.navigate([navItem.type, navItem.slug]);
      }
    }
    this.toggleNavigation();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  closeNav() {
    this.showNavigation = false;
  }
}
