import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[itTooltip]'
})
export class ITTooltipDirective {
  @Input() description: any;
  @Input() initiative: any;
  @Input() placement: string = 'top';
  @Input() delay: string = '500';
  @Input() offsetActive: boolean = false;
  @Input() innerHtml: boolean = false;
  @Input() hidden: boolean = false;
  tooltip: HTMLElement;
  offset = 20;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) { this.show(); }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) { this.hide(); }
  }

  @HostListener('click') onMouseClick() {
    if (this.tooltip) { this.hide(); }
  }

  show() {
    if(!this.hidden) {
      this.create();
      this.setPosition();
      this.renderer.addClass(this.tooltip, 'it-tooltip-show');
    }
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'it-tooltip-show');
    this.renderer.removeChild(document.body, this.tooltip);
    this.tooltip = null;
  }

  create() {
    this.tooltip = this.renderer.createElement('div');

    if (this.description && !this.innerHtml) {
      const p = this.renderer.createElement('p');
      this.renderer.appendChild(p, this.renderer.createText(this.description));
      this.renderer.appendChild(this.tooltip, p);
    }

    if (this.description && this.innerHtml) {
      const div = this.renderer.createElement('div');
      this.renderer.setProperty(div,'innerHTML',this.description)
      this.renderer.appendChild(this.tooltip, div);
      this.renderer.addClass(this.tooltip, 'it-tooltip__appointment');
    }

    this.renderer.appendChild(document.body, this.tooltip);
    // this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.renderer.addClass(this.tooltip, 'it-tooltip');
    this.renderer.addClass(this.tooltip, `it-tooltip-${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity 500ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity 500ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity 500ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity 500ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
      if (this.offsetActive) { left = left + this.offset + 20; }
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset - 12;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
      if (this.offsetActive) { left = left + this.offset + 20; }
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
