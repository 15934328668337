import { map, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { GlobalService } from '../services/global.service';

@Injectable()
export class ProductSearchResolver implements Resolve<any> {

  constructor(
    private router: Router,
    private globalService: GlobalService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.globalService.getProductTypes({include: 'property_groups.properties.options'})
    .pipe(
      map (
        (response) => {
          const productTypes = response.data;
          return {
            title: 'Pretraga',
            types: productTypes,
          };
        }
      )
    )
  }
}


