import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITTabComponent } from './tab.component';
import { ITTabsComponent } from './tabs.component';



@NgModule({
  declarations: [
    ITTabsComponent,
    ITTabComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ITTabsComponent,
    ITTabComponent
  ]
})
export class ITTabsModule { }
