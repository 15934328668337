import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PromoSectionConfig } from './promo-section-config';

@Component({
  selector: 'it-promo-section-configuration',
  templateUrl: './promo-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PromoSectionConfigurationComponent),
      multi: true
    }
  ]
})
export class PromoSectionConfigurationComponent implements OnInit {
  @Input() value: PromoSectionConfig = new PromoSectionConfig;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  positionOptions = [
    { id: 'top-left', name: 'Top Left' },
    { id: 'top-center', name: 'Top Center' },
    { id: 'top-right', name: 'Top Right' },

    { id: 'middle-left', name: 'Middle Left' },
    { id: 'middle-center', name: 'Middle Center' },
    { id: 'middle-right', name: 'Middle Right' },

    { id: 'bottom-left', name: 'Bottom Left' },
    { id: 'bottom-center', name: 'Bottom Center' },
    { id: 'bottom-right', name: 'Bottom Right' },
  ]

  constructor() { }

  ngOnInit() {
  }

  writeValue(value: any): void {
    this.value = new PromoSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

}
