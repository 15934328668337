<ng-container>
  <div class="it-row">
    <div class="it-col">
      <it-input
        [label]="'Number of products'"
        [(ngModel)]="value.limit"
        (ngModelChange)="onChange(value)"
      ></it-input>
    </div>
    <div class="it-col">
      <it-checkbox
        [label]="'New'"
        [(ngModel)]="value.new"
        (ngModelChange)="onChange(value);"
      ></it-checkbox>
    </div>
    <div class="it-col">
      <it-checkbox
        [label]="'Discounted'"
        [(ngModel)]="value.discounted"
        (ngModelChange)="onChange(value);"
      ></it-checkbox>
    </div>
    <div class="it-col">
      <it-checkbox
        [label]="'On promotion'"
        [(ngModel)]="value.on_promotion"
        (ngModelChange)="onChange(value);"
      ></it-checkbox>
    </div>
    <div class="it-col">
      <it-select
        [label]="'Category'"
        [options]="categoryOptions"
        [(ngModel)]="value.product_category_ids"
        (ngModelChange)="onChange(value)"
      ></it-select>
    </div>
  </div>
</ng-container>
