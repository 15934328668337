import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastConfig } from './_classes/toast-config';

@Component({
  selector: 'it-toast',
  templateUrl: './toast.component.html'
})
export class ITToastComponent implements OnInit {

  @Input() config: ToastConfig;
  @Output() componentDestroy: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {}

  close() {
    this.componentDestroy.emit();
  }
}
