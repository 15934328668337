import { Component, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit, Input } from '@angular/core';
import { ModalService } from '../../components/modal/modal.service';
import { ITTabComponent } from './tab.component';

@Component({
  selector: 'it-tabs',
  templateUrl: './tabs.component.html'
})
export class ITTabsComponent implements AfterContentInit {
  @ContentChildren(ITTabComponent) tabs: QueryList<ITTabComponent>;
  @Input() tabChangeValidation: boolean = false;
  @Input() disabled: boolean = false;
  @Input() position: string | 'default' | 'sticky' = 'default'
  @Output() notify = new EventEmitter();

  constructor(
    private modalService: ModalService
  ) {
  }

  ngAfterContentInit() {
    const activeTab = this.tabs.find(tab => tab.active);
    if (!activeTab) {
      setTimeout(() => {
        if (this.tabs && this.tabs.first) {
          this.tabs.first.active = true;
        }
      }, 500);
    }
  }

  select(tab: ITTabComponent) {
    if(this.disabled) { return; }
    if (this.tabChangeValidation) {
      this.modalService.confirm(
        {
          type: 'danger',
          title: 'Promena stranice',
          body: 'Da li ste sigurni da želite da napustite stranicu pre nego sto sačuvate nove podatke? Ova akcija se ne može poništiti.',
          buttons: [
            {
              role: 'cancel',
              text: 'Nazad'
            },
            {
              text: 'Dalje',
              handler: () => {
                this.tabs.toArray().forEach(tabItem => tabItem.active = tabItem === tab);
                this.notify.emit(tab);
              }
            },
          ]
        }
      );
    } else {
      this.tabs.toArray().forEach(tabItem => tabItem.active = tabItem === tab);
      this.notify.emit(tab);
    }
  }

}
