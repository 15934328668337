import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITImageComponent } from "./image.component";
import { FormsModule } from "@angular/forms";
import { ImageCropperModule } from "ngx-image-cropper";
import { ITButtonModule } from '../button/button.module';



@NgModule({
  declarations: [
    ITImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ImageCropperModule,
    ITButtonModule
  ],
  exports: [
    ITImageComponent
  ]
})
export class ITImageModule { }
