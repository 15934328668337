<div class="it-modal alert {{type}} {{size}}">
  <div class="it-modal__header" *ngIf="showTitle">
    <h2 class="it-modal__title">{{ title }}</h2>
    <span class="it-modal__close" (click)="close()"></span>
  </div>
  <div class="it-modal__body">
    <p [innerHTML]="body"></p>
  </div>
  <div class="it-modal__footer">
    <button
      (click)="onClick(button)"
      class="cancel">
      {{ button.text }}
    </button>
  </div>
</div>
