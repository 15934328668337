
export class NewsletterSectionConfig {
  placeholder: string = '';

  constructor(values: any = {}) {
    if (typeof values === 'string') {
      values = JSON.parse(values);
    } else {
      values = JSON.parse(JSON.stringify(values));
    }

    Object.assign(this, values);
  }
}
