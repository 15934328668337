export class NavigationItem {
  id: string;
  slug: string;
  title: string;
  type: NavigationItemType;
  navigation_id: string;
  parent_id: string;
  order: number;
  access: NavigationItemAccess;
  data: any;
  landing_page: boolean;
  published: boolean;

  entity_id: string;
  entity_type: string;
  entity: any;

  // Not returned from the api, used to make navigation
  subitems?: NavigationItem[];
  subsubitems?: NavigationItem[];

  created_at: string;
  updated_at: string;
}

export enum NavigationItemAccess {
  Public = 'public',
  Private = 'private',
}

export enum NavigationItemType {
  Page = 'page',
  PageCategory = 'page_category',
  Product = 'product',
  ProductCategory = 'product_category',
  ProductType = 'product_type',
  ExternalURL = 'external_url',
}