import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITInfiniteScrollComponent } from './infinite-scroll.component';



@NgModule({
  declarations: [
    ITInfiniteScrollComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ITInfiniteScrollComponent
  ]
})
export class ITInfiniteScrollModule { }
