import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) {
  }

  private static formatErrors(error: any) {
    return throwError(() => {
      return error;
    });
  }

  get(path: string, params: any = {}): Observable<any> {
    return this.http.get(`${environment.apiRoot}${path}`, { params, headers: { Authorization: 'Bearer ' + '144|pO1ylFkrv2fpmAZnRUxFz2Dqktus3mgfKW8ZiKyc'} })
      .pipe(catchError(ApiService.formatErrors))
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(`${environment.apiRoot}${path}`, body)
      .pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(`${environment.apiRoot}${path}`, body)
      .pipe(catchError(ApiService.formatErrors));
  }

  delete(path, params?: any): Observable<any> {
    return this.http.delete(`${environment.apiRoot}${path}`, { params })
      .pipe(catchError(ApiService.formatErrors));
  }

}
