import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllProductsSectionComponent } from './all-products-section.component';
import { AllProductsSectionConfigurationComponent } from './all-products-section-configuration.component';
import { FormsModule } from '@angular/forms';
import { ITButtonModule } from '../../components/button/button.module';
import { ITInputModule } from '../../components/input/input.module';
import { ITSelectModule } from '../../components/select/select.module';

@NgModule({
  declarations: [
    AllProductsSectionComponent,
    AllProductsSectionConfigurationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ITInputModule,
    ITButtonModule,
    ITSelectModule
  ],
  exports: [
    AllProductsSectionComponent,
    AllProductsSectionConfigurationComponent
  ]
})
export class AllProductsSectionModule { }
