export class ProductListSectionConfig {
  limit: number = 5;
  type: any = null;
  new: boolean = false;
  on_promotion: boolean = false;
  discounted: boolean = false;
  product_category_ids: any = null;

  constructor(values: any = {}) {
    if (typeof values === 'string') {
      values = JSON.parse(values);
    } else {
      values = JSON.parse(JSON.stringify(values));
    }

    Object.assign(this, values);
  }
}
