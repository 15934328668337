<ng-container>
  <div class="it-row">
    <div class="it-col">
      <it-input
        [label]="'Placeholder'"
        [(ngModel)]="value.placeholder"
        (ngModelChange)="onChange(value)"
      ></it-input>
    </div>
  </div>
</ng-container>
