import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITInputDirective } from './input.directive';
import { ITInputComponent } from './input.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ITInputComponent,
    ITInputDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    ITInputComponent,
    ITInputDirective
  ]
})
export class ITInputModule { }
