import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { ITSlideComponent } from './slide.component';

@Component({
  selector: 'it-slides',
  templateUrl: './slides.component.html'
})
export class ITSlidesComponent implements OnInit, AfterViewInit {
  @ContentChildren(ITSlideComponent) slides;
  @ViewChild('prev') prevRef: ElementRef;
  @ViewChild('next') nextRef: ElementRef;
  @ViewChild('pagination') paginationRef: ElementRef;

  @Input() autoplay: { delay: number };
  @Input() navigation: boolean = true;
  @Input() pagination: boolean = false;
  @Input() currentSlide: number;
  @Input() slidesPerView: number = 1;
  @Input() slidesBreakpoints: any = {};
  @Input() name: string;
  @Input() loop: boolean = false;

  config: any;

  mySwiper: Swiper;

  constructor() { }

  ngOnInit(): void {
    this.config = {
      direction: 'horizontal',
      slidesPerView: this.slidesPerView,
      autoHeight: true,
      keyboard: true,
      mousewheel: false,
      navigation: false,
      loop: this.loop,
      spaceBetween: 0,
      observer: true,
      pagination: false,
      breakpoints: this.slidesBreakpoints,
    };
  }

  ngAfterViewInit() {
    if (this.navigation) {
      this.config.navigation = {
        prevEl: this.prevRef?.nativeElement,
        nextEl: this.nextRef?.nativeElement,
      };
    }

    if (this.pagination) {
      this.config.pagination = {
        el: this.paginationRef?.nativeElement,
        type: 'bullets',
        clickable: true
      };
    }

    if (this.autoplay) {
      this.config.autoplay = this.autoplay;
    }
  }

}
