import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsletterSectionComponent } from './newsletter-section.component';
import { NewsletterSectionConfigurationComponent } from './newsletter-section-configuration.component';
import { FormsModule } from '@angular/forms';
import { ITInputModule } from '../../components/input/input.module';

@NgModule({
  declarations: [
    NewsletterSectionComponent,
    NewsletterSectionConfigurationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ITInputModule
  ],
  exports: [
    NewsletterSectionComponent,
    NewsletterSectionConfigurationComponent
  ]
})
export class NewsletterSectionModule { }
