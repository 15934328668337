import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListSectionComponent } from './product-list-section.component';
import { ProductListSectionConfigurationComponent } from './product-list-section-configuration.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ITInputModule } from '../../components/input/input.module';
import { ITSelectModule } from '../../components/select/select.module';
import { ITImageHandlerModule } from '../../directives/image-handler/image-handler.module';
import { ITLoadingModule } from '../../components/loading/loading.module';
import { ITSliderModule } from '../../components/carousel/slider.module';
import { ITCheckboxModule } from '../../components/checkbox/checkbox.module';
import { ITButtonModule } from '../../components/button/button.module';



@NgModule({
  declarations: [
    ProductListSectionComponent,
    ProductListSectionConfigurationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ITInputModule,
    ITSelectModule,
    ITImageHandlerModule,
    ITLoadingModule,
    ITSliderModule,
    ITCheckboxModule,
    ITButtonModule,
  ],
  exports: [
    ProductListSectionComponent,
    ProductListSectionConfigurationComponent
  ]
})
export class ProductListSectionModule { }
