<ng-container>
  <div class="it-row">
    <div class="it-col">
      <it-input
        [label]="'Number of columns'"
        [type]="'number'"
        [min]="1"
        [(ngModel)]="value.cols"
        (ngModelChange)="onChange(value);"
      ></it-input>
    </div>
    <div class="it-col">
      <it-input
        [label]="'Items'"
        [type]="'number'"
        [min]="1"
        [(ngModel)]="value.items"
        (ngModelChange)="onChange(value);addContent()"
      ></it-input>
    </div>
  </div>
</ng-container>
