import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  constructor(
    private apiService: ApiService
  ) { }
  getSections(ownerType: string, ownerId: string, params?: any) {
    return this.apiService.get(`${ownerType}/${ownerId}/sections`, params);
  }
  getSection(ownerType: string, ownerId: string, sectionId: string, params?: any) {
    return this.apiService.get(`${ownerType}/${ownerId}/sections/${sectionId}`, params);
  }
  createSections(ownerType: string, ownerId: string, params?: any) {
    return this.apiService.post(`${ownerType}/${ownerId}/sections`, params);
  }
  updateSection(ownerType: string, ownerId: string, sectionId: string, params?: any) {
    return this.apiService.put(`${ownerType}/${ownerId}/sections/${sectionId}`, params);
  }
  deleteSection(ownerType: string, ownerId: string, sectionId: string, params?: any) {
    return this.apiService.delete(`${ownerType}/${ownerId}/sections/${sectionId}`, params);
  }
  changeOrder(ownerType: string, ownerId: string, sectionId: string, params?: any) {
    return this.apiService.put(`${ownerType}/${ownerId}/sections/${sectionId}/order`, params);
  }
}
