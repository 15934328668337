import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private apiService: ApiService
  ) { }

  getMenus(params?: any) {
    return this.apiService.get(`navigation`, params);
  }
  getMenu(navigationId: string, params?: any) {
    return this.apiService.get(`navigation/${navigationId}`, params);
  }
  createMenu(params: any) {
    return this.apiService.post(`navigation`, params);
  }
  updateMenu(navigationId: string, params?: any) {
    return this.apiService.put(`navigation/${navigationId}`, params);
  }
  deleteMenu(navigationId: string) {
    return this.apiService.delete(`navigation/${navigationId}`);
  }

  getMenuItems(navigationId: string, params?: any) {
    return this.apiService.get(`navigation/${navigationId}/navigation_items`, params);
  }
  getMenuItem(navigationId: string, navigationItemId: string, params?: any) {
    return this.apiService.get(`navigation/${navigationId}/navigation_items/${navigationItemId}`, params);
  }
  createMenuItem(navigationId: string, params: any) {
    return this.apiService.post(`navigation/${navigationId}/navigation_items`, params);
  }
  updateMenuItem(navigationId: string, navigationItemId: string, params?: any) {
    return this.apiService.put(`navigation/${navigationId}/navigation_items/${navigationItemId}`, params);
  }
  deleteMenuItem(navigationId: string, navigationItemId: string, params?: any) {
    return this.apiService.delete(`navigation/${navigationId}/navigation_items/${navigationItemId}`, params);
  }
  changeMenuItemOrder(navigationId: string, navigationItemId: string, params?: any) {
    return this.apiService.put(`navigation/${navigationId}/navigation_items/${navigationItemId}/order`, params);
  }
}
