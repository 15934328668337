export class Section {
  id: string;
  order: number;
  type: string;
  title: string;
  data: any;
  custom_class: string;
  visibility: string;
  sectionable_id: string;
  sectionable_type: string;
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
