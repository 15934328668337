import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private apiService: ApiService
  ) { }

  getPageCategories(params?: any) {
    return this.apiService.get(`page_categories`, params);
  }

  getPageCategory(slug: string, params?: any) {
    return this.apiService.get(`page_categories/${slug}`, params);
  }

  getCategoryPages(params?: any) {
    return this.apiService.get(`pages`, params)
  }
  
  getPage(slug: string, params?: any) {
    return this.apiService.get(`pages/${slug}`, params);
  }

}
