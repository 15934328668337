import { Component, Input, OnInit} from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'it-chart',
  templateUrl: './charts.component.html'
})
export class ITChartsComponent implements OnInit {
  @Input() lineChartData: ChartDataSets[] = [{data: [], lineTension: 0 }];
  @Input() lineChartLabels: Label[] = [];
  @Input() lineChartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      ticks: {
        precision: 0
      },
      xAxes: [{
        gridLines: {
          display: false
        },
          ticks: {
            precision: 0,
            beginAtZero: true,
            fontSize: 13,
            fontWeight: 500,
            fontFamily: 'Prompt',
            fontColor: '#757575',
          }
      }],
      yAxes: [{
        ticks: {
          labelMaxWidth: 10,
          fontSize: 13,
          fontWeight: 500,
          fontFamily: 'Prompt',
          fontColor: '#313131',
        },
        gridLines: {
          display: false
        },
          stacked: true
      }]
  }
};

  @Input() lineChartColors: Color[] = [
    {
      backgroundColor: '#668DFF',
      borderColor: '#668DFF',
      borderWidth: 2
    }
  ];

  @Input() lineChartLegend = false;
  @Input() lineChartPlugins = [];
  @Input() lineChartType: any = 'horizontalBar';

  constructor() { }

  ngOnInit() {
  }
}
