<div class="it-modal error" [ngClass]="size">
  <div class="it-modal__header">
    <h2 class="it-modal__title">{{ title }}</h2>
    <span class="it-modal__close" #focusElement (click)="close()"></span>
  </div>
  <div class="it-modal__body">
    <div>
      {{errorMessage}}
    </div>
    <ul>
      <li *ngFor="let error of formattedErrors">
        {{error}}
      </li>
    </ul>
  </div>
  <div class="it-modal__footer">
      <button
        *ngFor="let button of buttons"
        (click)="onClick(button)"
        class="{{button.role}}">
        {{ button.text }}
      </button>
  </div>
</div>
