<div class="it-modal confirm {{type}} {{size}}">
  <div class="it-modal__header" *ngIf="showTitle">
    <h2 class="it-modal__title">{{ title }}</h2>
    <!-- <span class="it-modal__close" (click)="close()"></span> -->
  </div>
  <div class="it-modal__body" [innerHTML]="body"></div>
  <div class="it-modal__footer">
      <button
        *ngFor="let button of buttons; let i = index"
        (click)="onClick(button)"
        [ngClass]="{'primary': i === buttons.length - 1, 'cancel': button.role === 'cancel', 'danger': button.role === 'delete'}">
        {{ button.text }}
      </button>
  </div>
</div>
