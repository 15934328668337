<div class="it-section">
  <div class="it-section__edit-actions">
    <div class="action" (click)="update()">
      <span class="it-icon settings"></span>
      <span>Configure section</span>
    </div>
    <div class="spacer"></div>
    <div class="action double">
      <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
      <span class="it-icon arrow-down" (click)="sectionChangeOrder('down')"></span>
    </div>
    <div class="action delete" (click)="remove()">
      <span class="it-icon delete"></span>
    </div>
  </div>
</div>
