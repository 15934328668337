import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: '[itInfiniteScroll]',
  template: '<ng-content></ng-content>'
})

export class ITInfiniteScrollComponent {
  @Input() type: string | 'default' | 'website' = 'default';
  @Input() position: string | 'default' | 'middle' = 'default';
  @Output() scrolled = new EventEmitter();

  @HostListener('scroll', ['$event']) public onScroll(event) {
    const offsetHeight = event.target.offsetHeight;
    const scrollTop = event.target.scrollTop; //Math.round(event.target.scrollTop); Fires multiple end events
    const scrollHeight = event.target.scrollHeight;

    if(this.type === 'default' && this.position === 'default') {
      if (offsetHeight + scrollTop >= scrollHeight) {
        this.scrolled.emit();
      }
    }
    if(this.type === 'website' && this.position === 'default') {
      const footer: any = document.getElementsByClassName('ns-footer')[0];
      if (offsetHeight + scrollTop >= scrollHeight) {
        this.scrolled.emit();

      }
    }
  }

}
