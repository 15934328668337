import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITAlertComponent } from './alert/alert.component';
import { ITErrorComponent } from './error/error.component';
import { ITConfirmComponent } from './confirm/confirm.component';
import { ModalService } from './modal.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    ITAlertComponent,
    ITConfirmComponent,
    ITErrorComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    ModalService,
    NgbModal,
    NgbActiveModal
  ],
  exports: [
    ITAlertComponent,
    ITConfirmComponent,
    ITErrorComponent
  ]
})
export class ITModalModule { }
