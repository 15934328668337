import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITEmptyStateComponent } from './empty-state.component';



@NgModule({
  declarations: [
    ITEmptyStateComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ITEmptyStateComponent
  ]
})
export class ITEmptyStateModule { }
