import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { ITToastComponent } from './toast.component';
import { ToastConfig } from './_classes/toast-config';

@Injectable({
  providedIn: 'root'
})
export class ITToastService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  static checkChildCount(config) {
    const wrapper = document.getElementById(`it-toasts__${config.position}`);
    if (wrapper?.childElementCount <= 1) {
      document.body.removeChild(wrapper);
    }
  }

  show(config: ToastConfig) {
    if (!config.delay) { config.delay = 3000; }
    if (!config.position) { config.position = 'bottom-right'; }
    if (!config.effect) { config.effect = 'slide'; }

    const child = document.createElement('div');
    child.id = `it-toasts__${config.position}`;
    child.classList.add('it-toasts__wrapper');
    child.classList.add(config.effect);
    child.classList.add(config.position);
    const toastsWrapper = document.getElementById(`it-toasts__${config.position}`);
    if (!toastsWrapper) { document.body.appendChild(child); }

    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(ITToastComponent)
      .create(this.injector);

    const componentInstances = componentRef.instance;
    componentInstances.config = config;

    this.appRef.attachView(componentRef.hostView);

    const domElemToast = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.getElementById(`it-toasts__${config.position}`).appendChild(domElemToast);
    if (toastsWrapper && toastsWrapper.childElementCount > 1 && config.position !== 'top-full') { domElemToast.style.marginTop = '8px'; }

    if (config.delay !== 0) {
      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
        ITToastService.checkChildCount(config);
      }, config.delay);
    } else {
      componentInstances.componentDestroy.subscribe(
        () => {
          componentRef.destroy();
          ITToastService.checkChildCount(config);
        }
      );
    }
  }
}
