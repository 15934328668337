import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[itDropdownMenu]',
  templateUrl: './dropdown-menu.component.html'
})
export class ITDropdownMenuComponent implements OnInit {

  @Input() active: boolean = false;
  @Input() position: string | 'bottom' | 'top' | 'cursor' = 'cursor';
  @Input() left: number = 0;
  @Input() right: number = 0;
  top: number = null;

  constructor() { }

  ngOnInit() {
    if (this.active !== false) { this.active = true; }
  }

}
