import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { ITChartsComponent } from './charts.component';

@NgModule({
  declarations: [
    ITChartsComponent
  ],
  imports: [
    ChartsModule
  ],
  exports: [
    ITChartsComponent
  ],
})
export class ITChartsModule { }
