import { map, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { GlobalService } from '../services/global.service';

@Injectable()
export class ProductCategoryResolver implements Resolve<any> {

  constructor(
    private router: Router,
    private globalService: GlobalService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.globalService.getProductCategory(route.params.slug, {include: 'types.property_groups.properties.options'})
    .pipe(
      map (
        (response) => {
          const productCategory = response.data;
          return {
            categoryId: productCategory.id,
            title: productCategory.title,
            types: productCategory.types,
          };
        }
      )
    )
  }
}


