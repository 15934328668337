<div class="it-section__edit-actions" *ngIf="preview">
  <div class="action" (click)="update()">
    <span class="it-icon settings"></span>
    <span>Configure section</span>
  </div>
  <div class="spacer"></div>
  <div class="action double">
    <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
    <span class="it-icon arrow-down" (click)="sectionChangeOrder('down')"></span>
  </div>
  <div class="action delete" (click)="remove()">
    <span class="it-icon delete"></span>
  </div>
</div>

<div class="it-section__content-wrapper {{ section.custom_class }}">
  <div class="it-section__content newsletter">
    <h1 *ngIf="section.title" [innerHtml]="section.title"></h1>
    <div class="it-section__item">
      <it-input [placeholder]="configuration.placeholder" [buttonIcon]="'arrow-right'" [buttonFontSize]="'25'"></it-input>
    </div>
  </div>
</div>
