import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITAccordionsComponent } from './accordions.component';
import { ITAccordionComponent } from './accordion.component';



@NgModule({
  declarations: [
    ITAccordionsComponent,
    ITAccordionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ITAccordionsComponent,
    ITAccordionComponent
  ]
})
export class ITAccordionModule { }
