import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'it-empty-state',
  templateUrl: './empty-state.component.html'
})
export class ITEmptyStateComponent implements OnInit {

  @Input() type: string;
  @Input() message: string;

  constructor() { }

  ngOnInit() {
  }

}
