import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'it-radio',
  templateUrl: './radio.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ITRadioComponent),
      multi: true
    }
  ]
})
export class ITRadioComponent implements OnInit, ControlValueAccessor {

  @Input() formControlName: string;
  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;
  @Input() label: string;
  @Input() value: any;
  @Input() name: string = null;
  @Input() platform: 'mobile' | 'website' = 'website';

  @Output() valueChange = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() {
    if (this.disabled !== false) { this.disabled = true; }
    if (this.formControlName) { this.name = this.formControlName; }
  }

  writeValue(value: any) {
    if ((value && this.value) && this.value === value) { this.checked = true; }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChangeStatus(event) {
    if (this.onChange) { this.onChange(this.value); }
    this.valueChange.emit(this.value);
  }
}
