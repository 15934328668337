import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { User } from '../classes/user';
import { UserService } from '../services/user.service';
import { ScrollService } from '../services/scroll.service';
import { CompareService } from '../services/compare.service';
import { CartService } from '../services/cart.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'it-website',
  templateUrl: './website.component.html'
})
export class WebsiteComponent implements OnInit, OnDestroy {
  user: User;
  navigationType: any;

  navigations: any[] = [];
  mainNavigation: any[] = [];
  footerNavigation: any[] = [];
  additionalNavigation: any[] = [];

  compareProducts: any[] = [];
  compareProductsShow: any[] = [];

  pageClass: string = '';
  cart: any;

  fileRoot: string = environment.fileRoot;

  showFooter: boolean = true;
  showCompareBubble: boolean = false;
  showResponsiveNav: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private scrollService: ScrollService,
    private compareService: CompareService,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const website = this.route.snapshot.data.website;

    // Find landing page if land on root
    if(!route.children.length) {
      let landingPage; 
      
      website.navigations.forEach(navigation => {
        const page = navigation.navigation_items.find(ni => ni.landing_page);
        if (page) { landingPage = page }
      });

      if (landingPage) {
        this.router.navigate([landingPage.type, landingPage.slug]);
      }
    }
  }

  ngOnInit() {
    // console.log(this.router.url)
    this.pageClass = this.router.url.split('/').join(' ');

    const userSubscription = this.userService.currentUser
      .subscribe({
        next: (response: User) => {
          this.user = response;
        },
        error: () => {}
      });
    this.subscriptions.push(userSubscription);

    const compareProductsSubscription = this.compareService.compareProducts
      .subscribe({
        next: (response) => {
          if(!response) {
            this.compareProducts = this.compareService.getCompareProducts();
            if(!this.compareProducts) {
              this.compareProducts = [];
            }
          }else {
            this.compareProducts = response;
          }
          if(!this.compareProducts || this.compareProducts.length === 0) { this.showCompareBubble = false; }
          this.compareProductsConfiguration();
        },
        error: () => {}
      })
    this.subscriptions.push(compareProductsSubscription);

    const routerSubscription = this.router.events
      .subscribe(
        (routerEvent: any) => {
          if (routerEvent instanceof NavigationEnd) {
            // console.log(routerEvent.url);
            this.pageClass = routerEvent.url.split('/').join(' ');

            if (
              routerEvent.url.includes('/product/category') ||
              routerEvent.url.includes('/product/type') ||
              routerEvent.url.includes('/product/search')
            ) {
              this.showFooter = false;
            } else {
              this.showFooter = true;
            }
          }
        }
      );
    this.subscriptions.push(routerSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onScroll(event) {
    if(event.target.scrollTop >= 300) {
      this.showResponsiveNav = true;
    } else {
      this.showResponsiveNav = false
    }
  }

  scrolledToBottom(event) {
    this.scrollService.getScrollOffset(event);
  }

  toggleCompareBubble() {
    this.showCompareBubble = !this.showCompareBubble;
  }

  compareProductsConfiguration() {
    this.compareProductsShow = [];

    this.compareProducts.forEach(element => {
      if(!this.compareProductsShow.find(p => p.product_type_id === element.product_type_id)){
        this.compareProductsShow.push({
          product_type_id: element.product_type_id,
          name: element.type.title,
          products: [{id: element.id, name: element.name, image: element.image_urls ? element.image_urls[0] : ''}],
        })
      } else {
        this.compareProductsShow.find(p => p.product_type_id === element.product_type_id).products.push({id: element.id, name: element.name, image: element.image_urls ? element.image_urls[0] : ''})
      }
    });
  }

  deleteCompareProduct(productId: string) {
    this.compareService.removeCompareProduct(productId);
  }

  compare(typeId: string) {
    this.showCompareBubble = false;
    this.router.navigate(['/product-compare'], { queryParams: { type: typeId } });
  }

  getCart() {
    this.cart = this.cartService.getCart();
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      const main = document.querySelector('.website-root main');
      main.scrollTo({ top: 0, behavior:'smooth' })
    }
  }

}
