import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) {
  }

  private static formatErrors(error: any) {
    return throwError(() => {
      if (error.status === 401) {
        localStorage.removeItem('jwt');
        window.location.reload();
      }
      return error;
    });
  }

  get(path: string, params: any = {}): Observable<any> {
    return this.http.get(`${environment.apiRoot}${path}`, { params })
      .pipe(catchError(ApiService.formatErrors))
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(`${environment.apiRoot}${path}`, body)
      .pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(`${environment.apiRoot}${path}`, body)
      .pipe(catchError(ApiService.formatErrors));
  }

  delete(path: string, params?: any): Observable<any> {
    return this.http.delete(`${environment.apiRoot}${path}`, { params })
      .pipe(catchError(ApiService.formatErrors));
  }

  download(path: string, filename: string): Observable<any> {
    return this.http.get(`${environment.apiRoot}${path}`, { responseType: 'blob' })
      .pipe(map((response: any) => {

        const file = new Blob([response]);

        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }));
  }

  sync(params: any = {}): Observable<any> {
    return this.http.post(`${environment.apiRoot}sync`, { params })
      .pipe(catchError(ApiService.formatErrors))
  }

}
