import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'it-slide',
  templateUrl: './slide.component.html'
})
export class ITSlideComponent implements OnInit {

  constructor() { }

  @Input() image;
  @Input() fileRoot;

  @HostBinding('class.swiper-slide') hostClass(): any {}

  ngOnInit() {
  }

}
