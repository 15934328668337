<div class="chart-container" style="position: relative; min-height: 34vh; height: 100%; width: 100%; margin: auto">
  <canvas baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins">
  </canvas>
</div>
