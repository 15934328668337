import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '../../components/modal/modal.service';
import { Category } from '../../../../../../apps/admin/src/app/classes/category';
import { ProductService } from '../../../../../../apps/admin/src/app/services/product.service';
import { CategoriesSectionConfig } from './categories-section-config';

@Component({
  selector: 'it-categories-section-configuration',
  templateUrl: './categories-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoriesSectionConfigurationComponent),
      multi: true
    }
  ]
})
export class CategoriesSectionConfigurationComponent implements OnInit {
  @Input() value: CategoriesSectionConfig = new CategoriesSectionConfig;

  categories: Category[] = [];

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor(
    private modalService: ModalService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  writeValue(value: any): void {
    this.value = new CategoriesSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getCategories() {
    const params = {
      sort: 'title',
      direction: 'asc',
      pagination: false,
    }
    this.productService.getProductCategories(params)
      .subscribe({
        next: (response) => {
          this.categories = response.data;
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
        }
      })
  }


}
