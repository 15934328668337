import {
  Component,
  HostListener,
  Input,
  OnInit,
  ContentChild,
  HostBinding
} from '@angular/core';
import { ITDropdownMenuComponent } from './dropdown-menu.component';

@Component({
  selector: '[itDropdown]',
  templateUrl: './dropdown.component.html'
})
export class ITDropdownComponent implements OnInit {
  @HostBinding('class.it-dropdown') class = true;
  @HostBinding('class.show') show = false;

  @ContentChild(ITDropdownMenuComponent) dropdownMenu: ITDropdownMenuComponent;

  @Input() position: string | 'bottom' | 'top' | 'cursor' = 'cursor';
  @Input() left: number = 0;
  @Input() disabled: boolean = false;

  @HostListener('click', ['$event']) onClick(event) {
    this.dropdownMenu.position = this.position;
    if (this.position === 'cursor') {
      this.dropdownMenu.top = event.layerY;
      this.dropdownMenu.left = event.layerX;
    }
    this.dropdownMenu.active = !this.dropdownMenu.active;
    this.show = !this.show;
  }

  constructor() { }

  ngOnInit() {}

  close() {
    this.dropdownMenu.active = false;
  }

}
