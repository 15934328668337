<div class="it-dropdown__menu"
     [ngClass]="{
     'show': active,
     'bottom': position === 'bottom',
     'top': position === 'top'
     }"
     [ngStyle]="{
     'right': (position === 'cursor' && !right) ? left/2 + 'px' : left + 'px',
     'top': position === 'cursor' ? top + 'px' : top + 'px'}">
  <ng-content></ng-content>
</div>

<!-- 'left': position === 'cursor' ? left/2 + 'px' : left + 'px', -->

