import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationItem, NavigationItemType } from '../../classes/navigation-item';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'it-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  navigationItems: any;
  mainNavigation: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    const website = this.route.snapshot.data.website;
    const navigation = website.navigations.find(n => n.slug === 'footer-navigation');
    this.navigationItems = navigation.navigation_items.sort((a, b) => { return a.order - b.order; });
    this.getNavigation();
  }

  getNavigation() {
    this.mainNavigation = this.navigationItems.filter(navItem => !navItem.parent_id);
    this.mainNavigation.sort((a, b) => { return a.order - b.order; });
    if(this.mainNavigation) {
      this.mainNavigation.map(mainNavItem => {
        mainNavItem.subitems = this.navigationItems.filter(navItem => mainNavItem.id === navItem.parent_id);
        mainNavItem.subitems.sort((a, b) => { return a.order - b.order; });
        if(mainNavItem.subitems) {
          mainNavItem.subitems.map(subitem => {
            subitem.subsubitems = this.navigationItems.filter(navItem => subitem.id === navItem.parent_id);
            subitem.subsubitems.sort((a, b) => { return a.order - b.order; });
          })
        }
      })
    }
  }

  navigate(navigationItem: NavigationItem): void {
    // Resolve route
    switch (navigationItem.type) {
      case NavigationItemType.ExternalURL:
        if (isPlatformBrowser(this.platformId)) {
          window.open(navigationItem.data.external_url, '_blank');
        }
        break;
      case NavigationItemType.Page:
        this.router.navigate(['/page', navigationItem.entity.slug]);
        break;
      case NavigationItemType.PageCategory:
        this.router.navigate(['/page', 'category', navigationItem.entity.slug]);
        break;
      case NavigationItemType.Product:
        this.router.navigate(['/product', navigationItem.entity.slug]);
        break;
      case NavigationItemType.ProductCategory:
        this.router.navigate(['/product', 'category', navigationItem.entity.slug]);
        break;
      case NavigationItemType.ProductType:
        this.router.navigate(['/product', 'type', navigationItem.entity.slug]);
        break;
    
      default:
        break;
    }
  }

}
