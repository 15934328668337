<div class="it-text-editor {{type}}" *ngIf="editor">
    <span class="it-text-editor__label" *ngIf="label">
        {{label}}
        <sup class="required" *ngIf="label && required">*</sup>
    </span>
    <ckeditor
            (change)="onChangeValue($event);"
            (ngModelChange)="displayError()"
            (blur)="displayError();"
            (ready)="onReady($event)"

            [editor]="editor"
            [config]="config"
            [disabled]="disabled"
            [required]="required"
            [(ngModel)]="value"
            [ngClass]="{'error': errors.length > 0, 'disabled': disabled}"
    ></ckeditor>
    <div *ngIf="errors.length > 0" class="it-input-error-box">
        <div *ngIf="errors.length === 1" class="it-input-error single">
            <div>{{ errorMessages[errors[0]] || errors[0] }}</div>
        </div>
        <div *ngIf="errors.length > 1" class="it-input-error multi">
            <p (click)="toggleErrors()">
                <span>You have {{ errors.length }} errors</span>
            </p>
            <div *ngIf="showErrors">
                <ul>
                    <li *ngFor="let error of errors">
                        {{ errorMessages[error] || error }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

