<div class="it-image"
     [ngClass]="{'readyToDrop': readyToDrop, 'disabled': disabled, 'large': size === 'large'}"  (dragenter)="dragEnter($event)"
     (dragover)="dragEnter($event)"
     (dragleave)="dragLeave($event)"
     (drop)="onDrop($event)"
     (click)="choseFile($event)">
  <span class="it-image__icon {{icon}}" *ngIf="icon" [ngClass]="{'it-icon': icon}"></span>
  <div class="it-image__label">{{label}}</div>
  <label>
    <input
      [type]="'file'"
      [accept]="'.png,.jpg,.jpeg'"
      [hidden]="true"
      [name]="name"
      [disabled]="disabled"
      [required]="required"
      [multiple]="multiple"
      (input)="emitChange($event)"
      #inputFile>
  </label>
  <label>
    <input type="text" [(ngModel)]="value" hidden>
  </label>
  <div class="it-image__preview"
       *ngIf="value"
       [style.backgroundImage]="'url(' + value + ')'"></div>
</div>
<div class="it-image__actions" *ngIf="value">
  <span class="it-icon upload" (click)="choseFile($event)"><span class="ml-8">Replace</span></span>
  <span class="it-icon delete" (click)="delete()"><span class="ml-8">Remove</span></span>
</div>

<div class="it-image__cropper-wrapper" [class.show]="showCropper">
  <div class="it-image__cropper">
    <div class="it-image__cropper__title">
      <h2>Upload photo</h2>
      <span class="it-image__cropper__close" (click)="closeCropper()"></span>
    </div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [imageBase64]="imageBase64"
      [maintainAspectRatio]="true"
      [aspectRatio]="cropperAspectRation"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      [backgroundColor]="'#fff'"
      [resizeToHeight]="cropperHeight"
      [resizeToWidth]="cropperWidth"
    ></image-cropper>
    <div class="it-image__cropper-actions">
      <it-button color="outline" (click)="closeCropper()">Cancel</it-button>
      <it-button color="primary" (click)="setCroppedImage()">Apply</it-button>
    </div>
  </div>
</div>

