import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomSectionConfig } from './custom-section-config';

@Component({
  selector: 'it-custom-section-configuration',
  templateUrl: './custom-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSectionConfigurationComponent),
      multi: true
    }
  ]
})
export class CustomSectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: CustomSectionConfig = new CustomSectionConfig;
  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() {}

  writeValue(value: any): void {
    this.value = new CustomSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  addContent() {
    if(this.value.contents.length === 0) {
      for(let i = 0; i < +this.value.items; i++) {
        this.value.contents.push({
          id: 'content-' + (i + 1),
          text: ''
        });
      }
    }else {
      if(this.value.contents.length < +this.value.items) {
        for(let i = this.value.contents.length; i < +this.value.items; i++) {
          this.value.contents.push({
            id: 'content-' + (i + 1),
            text: ''
          });
        }
      }else {
        this.value.contents.splice(-(this.value.contents.length - +this.value.items));
      }
    }
  }

}
