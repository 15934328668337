import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '../../components/modal/modal.service';
import { ProductService } from '../../../../../../apps/admin/src/app/services/product.service';
import { ProductListSectionConfig } from './product-list-section-config';

@Component({
  selector: 'it-product-list-section-configuration',
  templateUrl: './product-list-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductListSectionConfigurationComponent),
      multi: true
    }
  ]
})
export class ProductListSectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: ProductListSectionConfig = new ProductListSectionConfig;

  categoryOptions: any[] = [];
  showCategoryOptions: boolean = false;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor(
    private modalService: ModalService,
    private productService: ProductService,
  ) { }

  ngOnInit() {
    this.getProductCategories();
  }

  writeValue(value: any): void {
    this.value = new ProductListSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getProductCategories() {
    this.productService.getProductCategories()
      .subscribe({
        next: (response) => {
          this.categoryOptions = response.data;
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
        }
      });
  }

}
