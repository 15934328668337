import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsiteComponent } from './website.component';
import { WebsiteResolver } from '../resolvers/website.resolver';
import { NavigationModule } from '../components/navigation/navigation.module';
import { FooterModule } from '../components/footer/footer.module';
import { RouterModule } from '@angular/router';
import { WebsiteRoutes } from './website.routes';
import { PageCategoryResolver } from '../resolvers/page-category.resolver';
import { ProductCategoryResolver } from '../resolvers/product-category.resolver';
import { ProductTypeResolver } from '../resolvers/product-type.resolver';
import { ProductSearchResolver } from '../resolvers/product-search.resolver';
import { WebsiteInterceptor } from '../services/website.interceptor';
import { TokenInterceptor } from '../services/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ITButtonModule, ITImageHandlerModule, ITInfiniteScrollModule, ITModalModule } from '@infoteam/infoteam-ui';

@NgModule({
  declarations: [
    WebsiteComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(WebsiteRoutes),
    ReactiveFormsModule,
    FormsModule,
    NavigationModule,
    FooterModule,
    ITModalModule,
    ITInfiniteScrollModule,
    ITButtonModule,
    ITImageHandlerModule,
  ],
  providers: [
    WebsiteResolver,
    PageCategoryResolver,
    ProductCategoryResolver,
    ProductTypeResolver,
    ProductSearchResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WebsiteInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ]
})
export class WebsiteModule { }
