import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef, Host,
  Input, OnChanges, OnDestroy,
  OnInit, Optional,
  Output, Renderer2, SimpleChanges, SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITSelectItem } from './classes/select-item';

@Component({
  selector: 'it-select',
  templateUrl: './select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ITSelectComponent),
    multi: true
  }]
})

export class ITSelectComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, OnDestroy {
  @ViewChild('searchInput', { read: ElementRef, static: false }) searchInput: ElementRef;
  @ViewChild('selectEl', { read: ElementRef, static: false }) selectEl: ElementRef;
  @ViewChild('listEl', { read: ElementRef, static: false }) listEl: ElementRef;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  @Input() name: string;
  @Input() formControlName: string;
  @Input() label: string;
  @Input() hideLabel: boolean = false;
  @Input() placeholder: string;
  @Input() searchPlaceholder: string = 'Search...';
  @Input() value: any;
  @Input() showSearch: boolean = false;
  @Input() multi: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() requiredType: 'default' | 'full' = 'default' ;
  @Input() showAddButton: boolean = false;
  @Input() addButtonText: string;
  @Input() addButtonShowValue: boolean = false;
  @Input() emptyStateText: string;
  @Input() notFoundText: string;
  @Input() actionInfoText: string;
  @Input() errorMessages: any = {};
  @Input() size: 'small' | 'default' = 'default';
  @Input() options: any[] = [];
  @Input() selectedOptions: any[] = [];
  @Input() showSelectAll: boolean = false;
  @Input() loading: boolean = false;
  @Input() autoPosition: boolean = true;
  @Input() searchInputNotInFocus: boolean = false;
  @Input() showClearButton: boolean = true;
  @Input() searchTimeout: number = 500;
  @Input() tabindex: number = 0;
  @Input() searchTerm: string = '';
  @Input() searchMask: boolean = false;
  @Input() showPhoneNumber: boolean = false;
  @Input() showError: boolean = false;
  @Input() searchMaskType: string | 'phone' = 'phone';

  @Output() addButtonClick = new EventEmitter();
  @Output() loadData = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() searched = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  showList: boolean;
  selected: any[] = [];
  selectedAll: boolean = false;

  errors: any[] = [];
  showErrors: boolean = false;
  optionClicked: boolean = false;

  clearing: boolean = false;
  delaySearch: any;
  selectOverlayContainer: HTMLElement;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.required !== false) { this.required = true; }
    if (this.autoFocus !== false) { this.autoFocus = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
    if (this.showSearch !== false) { this.showSearch = true; }
    if (this.addButtonShowValue !== false) { this.addButtonShowValue = true; }
    if (this.multi !== false) { this.multi = true; }
    if (this.showSelectAll !== false) { this.showSelectAll = true; }
    if (this.showAddButton !== false) { this.showAddButton = true; }
    if (this.loading !== false) { this.loading = true; }
    if (this.autoPosition !== false) { this.autoPosition = true; }
    this.checkRequired();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.autoFocus) {
        if (this.selectEl) {
          this.selectEl.nativeElement.focus();
          this.selectEl.nativeElement.click();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.options) {
      this.options = this.options.map((option) => {
        const newOption: ITSelectItem = {
          id: option.id,
          name: option.name || option.title || option.value,
          entity: option
        };

        if (option.first_name || option.last_name ) {
          newOption.name = (option.title ? option.title + ' ' : '') + option.first_name + ' ' + option.last_name;
        }
        return newOption;
      });
      this.calculateSelectOptions();
    }
    if (changes.selectedOptions && this.selectedOptions) {
      this.selectedOptions = this.selectedOptions.map((option) => {
        const newOption: ITSelectItem = {
          id: option.id,
          name: option.name || option.title || option.value,
          entity: option
        };

        if (option.first_name || option.last_name ) {
          newOption.name = (option.title ? option.title + ' ' : '') + option.first_name + ' ' + option.last_name;
        }
        return newOption;
      });
      this.calculateSelectOptions();
    }
  }

  ngOnDestroy() {
    this.hideList();
  }

  writeValue(value: any) {
    this.value = value;
    if (value != null || value != undefined) {
      this.calculateSelectOptions();
    }
    if (value == null || value == undefined || value.length === 0) {
      this.selected = [];
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  toggle() {
    if (this.disabled) { return; }
    if (!this.showList) {
      this.show();
    } else {
      this.hide();
    }
  }

  showListToggle() {
    if (this.disabled) { return; }
    if (!this.showList) {
      this.listEl.nativeElement.style.width = this.selectEl.nativeElement.offsetWidth + 'px';
      if (this.searched) {
        setTimeout(() => {
          if (this.searchInput) {
            this.searchInput.nativeElement.focus();
          }
        }, 300);
      }
    } else {
      this.displayError();
    }
  }

  hideList(event?: any) {
    if ((event && event.srcElement.className === 'it-select-overlay-container')) {
      if (this.selectOverlayContainer) { this.hide(); }
      this.showList = false;
      this.selectOverlayContainer = null;
    }
  }

  multiHideList() {
    this.showList = false;
    if (this.selectOverlayContainer) { this.hide(); }
    this.selectOverlayContainer = null;
  }

  optionSelect(option) {
    if (this.multi) {
      event.stopPropagation();
      if (!this.value) { this.value = []; }
      const index = this.value.indexOf(option.id);
      if (index !== -1) {
        this.value.splice(index, 1);
        this.selectedAll = false;
      } else {
        this.value.push(option.id);
      }
      this.calculateSelectOptions();
    } else {
      this.optionClicked = true;
      this.selected = [option];
      this.value = option.id;
      this.showList = false;
      this.searchTerm = '';
      this.hide();
      setTimeout(() => {
        this.optionClicked = false;
      }, 600);
    }

    this.emitChange();
    this.displayError();
  }

  calculateSelectOptions() {
    if ((this.value != null || this.value != undefined) && this.options) {
      const options = this.options;
      if (this.selectedOptions) {
        this.selectedOptions.forEach(option => {
          if (!this.options.find(o => o.id === option.id)) {
            options.push(option);
          }
        });
      }

      if (this.value.length && this.multi) {
        this.selected = options.filter((option) => {
          return this.value.indexOf(option.id) !== -1;
        });
      } else {
        this.selected = options.filter((option) => {
          return this.value == option.id;
        });
      }
    }
  }

  clearOptions(event) {
    event.stopPropagation();
    this.selected = [];
    this.value = this.multi ? [] : null;
    if (!this.showList) { this.clearing = true; }
    setTimeout(() => { this.clearing = false; }, 200);
    this.calculateSelectOptions();
    this.emitChange();
    this.displayError();
  }

  emitSearch() {
    clearTimeout(this.delaySearch);
    this.delaySearch = setTimeout(() => {
      let maskTerm = null;
      if (this.searchMask && this.searchMaskType === 'phone') {
        maskTerm = this.searchTerm.split(' ').join(''); this.searched.emit(maskTerm);
      } else {
        this.searched.emit(this.searchTerm);
      }
    }, this.searchTimeout);
  }

  emitLoadMore(event) {
    this.loadMore.emit();
  }

  emitChange() {
    if (this.onChange) {
      this.onChange(this.value);
    }
    this.valueChange.emit(this.value);
  }

  addClick(type?: string) {
    if (type === 'search') {
      if (this.options.length === 0 || !this.options) {
        this.addButtonClick.emit(this.searchTerm);
      }
    } else {
      if (this.searchTerm.length > 0) {
        this.addButtonClick.emit(this.searchTerm);
      } else {
        this.addButtonClick.emit( null );
      }
    }
    this.hideList();
  }

  checkRequired() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.validator) {
      const validators = formControl.validator({} as AbstractControl);
      if (validators && validators.required) {
        this.required = true;
      }
    }
  }

  displayError() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.errors) {
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
    } else {
      this.errors = [];
    }
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  selectAll() {
    if (!this.selectedAll) {
      this.value = [];
      this.options.map((option) => {
        this.value.push(option.id);
      });
    } else {
      this.value = [];
    }
    this.selectedAll = !this.selectedAll;
    this.calculateSelectOptions();
    this.emitChange();
    this.displayError();
  }

  show() {
    this.create();
    this.listEl.nativeElement.style.width = this.selectEl.nativeElement.offsetWidth + 'px';
    this.showList = true;
    if (this.searched) {
      setTimeout(() => {
        if (this.searchInput) {
          this.searchInput.nativeElement.focus();
        }
      }, 300);
    }
  }

  hide() {
    this.renderer.removeClass(this.selectOverlayContainer, 'it-select-overlay-container');
    this.renderer.removeChild(document.body, this.selectOverlayContainer);
    this.showList = false;
    this.selectOverlayContainer = null;
  }

  create() {
    this.selectOverlayContainer = this.renderer.createElement('div');
    this.renderer.appendChild(document.body, this.selectOverlayContainer);
    this.renderer.addClass(this.selectOverlayContainer, 'it-select-overlay-container');
    this.renderer.appendChild(this.selectOverlayContainer, this.listEl.nativeElement);
    this.selectOverlayContainer.addEventListener('click', () => { this.hide(); })
    this.setPosition();
  }

  setPosition() {
    const hostPos = this.selectEl.nativeElement.getBoundingClientRect();
    const selectListPos = this.listEl.nativeElement.getBoundingClientRect();
    const space = window.innerHeight - hostPos.bottom;

    const offset = 40;
    let top = 20;
    let bottom = 20;
    let left = 20;
    let placement;
    if (space < this.listEl.nativeElement.scrollHeight) { placement = 'top'; } else { placement = 'bottom'; }

    if (placement === 'top') {
      bottom = hostPos.top - this.listEl.nativeElement.scrollHeight - 8;
      left = hostPos.left + (hostPos.width - selectListPos.width) / 2;
      this.renderer.setStyle(this.listEl.nativeElement, 'top', `${bottom}px`);
    }

    if (placement === 'bottom') {
      top = hostPos.top + offset;
      left = hostPos.left + (hostPos.width - selectListPos.width) / 2;
      if(left < 0) { left = 0 ;}
      this.renderer.setStyle(this.listEl.nativeElement, 'top', `${top}px`);
    }
    this.renderer.setStyle(this.listEl.nativeElement, 'left', `${left}px`);
  }

}
