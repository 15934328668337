<footer class="it-footer">
  <div class="it-container">
    <nav class="it-row center footer-navigation">
      <div class="it-col md-3" *ngFor="let item of mainNavigation">
        <ul>
          <li class="title">{{ item.title }}</li>
          <li class="it-clickable"
            *ngFor="let subitem of item.subitems" 
            (click)="navigate(subitem)"
          >{{ subitem.title }}</li>
        </ul>
      </div>
      <div class="it-col md-3">
        <ul>
          <li class="title">Kontakt</li>
          <li class="contact">
            <span class="ns-icon contact-address"></span>
            <div>
              Infoteam d.o.o.<br />
              Bulevar Kralja Aleksandra 193,<br />
              11160 Beograd, Srbija
            </div>
          </li>
          <li class="contact">
            <span class="ns-icon contact-email"></span>
            <div>
              <a href="mailto:info@infoteam.rs">info&#64;infoteam.rs</a>
            </div>
          </li>
        </ul>
      </div>
      <div class="it-col md-12 social-media">
        <a href="https://www.facebook.com/GarminSrbija" target="_blank" class="it-icon facebook"></a>
        <a href="https://www.instagram.com/garminsrbija" target="_blank" class="it-icon instagram"></a>
      </div>
    </nav>
    <div class="it-row">
      <div class="it-col copyright">
        <span>Copyright© Infoteam d.o.o. Sva prava zadržana.</span>
      </div>
    </div>    
  </div>
</footer>