import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';

@Component({
  selector: 'it-section-panel',
  templateUrl: './section-panel.component.html'
})
export class SectionPanelComponent implements OnInit {
  @Input() section: any;
  @Input() ownerType: string;
  @Input() ownerId: string;
  @Input() sectionType: string;

  sectionForm: UntypedFormGroup;


  visibilityOptions = [
    {
      id: 'all',
      name: 'All screen sizes'
    },
    {
      id: 'desktop',
      name: 'Desktop only'
    },
    {
      id: 'mobile',
      name: 'Mobile only'
    },
    {
      id: 'hidden',
      name: 'Hidden'
    }
  ];


  constructor(
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private sectionService: SectionService,
    private toastService: ITToastService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
    if(this.section) {
      this.sectionForm.patchValue(this.section);
      this.sectionForm.get('data').setValue(this.section.data);
    }
  }

  initForm() {
    this.sectionForm = this.formBuilder.group({
      title: [null],
      type: [this.sectionType],
      order: [null],
      custom_class: [null],
      visibility: ['all'],
      data: [null]
    });
  }

  save() {
    const params: any = {
      ...this.sectionForm.value
    }

    if (typeof params.data === 'string') {
      params.data = JSON.parse(params.data);
    } else {
      params.data = JSON.parse(JSON.stringify(params.data));
    }

    if(!this.section) {
      this.sectionService.createSections(this.ownerType, this.ownerId, params)
      .subscribe({
        next: (response) => {
          this.toastService.show({
            type: 'success', 
            message: 'Section has been successfully created.'
          });
          this.activeModal.close(response);
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
        }
      })
    }else {
      this.sectionService.updateSection(this.ownerType, this.ownerId, this.section.id, params)
      .subscribe({
        next: (response) => {
          this.toastService.show({
            type: 'success',
            message: 'Section has been successfully updated.'
          });
          this.activeModal.close(response);
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
        }
      })
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
