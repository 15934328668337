import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PaginationConfig } from './classes/pagination-config';


@Component({
    selector: 'it-pagination',
    templateUrl: './pagination.component.html'
})

export class ITPaginationComponent implements OnInit, OnChanges {
    @Input() type: string | 'default' | 'thorough' = 'default';
    @Input() config: PaginationConfig;
    @Input() pagesShow: number = 5;
    @Input() showPerPage: boolean;
    @Input() perPageOptions: any[] = [5, 10, 20, 50, 100];
    @Input() info: boolean = false;
    @Input() showTotalResult: boolean = false;
    @Input() totalResultText: string = 'Results';
    @Output() paginationChange = new EventEmitter<PaginationConfig>();

    pages: number[];
    startPage: number;
    endPage: number;
    optionsPerPage: any[] = [];

    constructor() { }

    ngOnInit() {
        if (this.info !== false) { this.info = true; }

        if (!this.config.per_page) {
          this.config.per_page = this.perPageOptions[0];
        }
        this.perPageCheck();
        this.perPageOptions.map((option) => {
          this.optionsPerPage.push({id: option, name: option});
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.config) {
          this.config = changes.config.currentValue;
          if (this.config === changes.config.currentValue) {
              this.setPage(this.config.current_page);
          }
        }
    }

    setPage(currentPage: number) {
      if (this.config.current_page < 1 || this.config.current_page > this.config.total_pages) {
          return;
      }

      this.config.current_page = currentPage;
      this.config.total_pages = Math.ceil(this.config.total / this.config.per_page);

      if (currentPage <= 1) {
          this.config.current_page = 1;
      } else if (currentPage > this.config.total_pages) {
          this.config.current_page = this.config.total_pages;
      }

      if (this.config.total_pages <= this.pagesShow) {
          this.startPage = 1;
          this.endPage = this.config.total_pages;
      } else {
          if (currentPage <= this.pagesShow -  Math.floor(this.pagesShow / 2)) {
              this.startPage = 1;
              this.endPage = this.pagesShow;
          } else if (currentPage + Math.floor(this.pagesShow / 2) >= this.config.total_pages) {
              this.startPage = this.config.total_pages - (this.pagesShow - 1);
              this.endPage = this.config.total_pages;
          } else {
              this.startPage = currentPage - Math.floor(this.pagesShow / 2);
              this.endPage = currentPage +  Math.floor(this.pagesShow / 2);
          }
      }
      this.pages = Array.from(Array((this.endPage + 1) - this.startPage).keys()).map(i => this.startPage + i);
    }

    changePagination() {
      this.paginationChange.emit(this.config);
    }

    changePaginationOnEvent() {
      this.config.current_page = 1;
      this.setPage(this.config.current_page);
      if (!this.config.per_page) {
        this.config.per_page = this.perPageOptions[0];
      }
      this.paginationChange.emit(this.config);
    }

    perPageCheck() {
      if (this.perPageOptions.indexOf(this.config.per_page) === -1) {
        const index = this.perPageOptions.findIndex(number => number > this.config.per_page );
        this.perPageOptions.splice(index, 0, this.config.per_page);
      }
    }
}


