<ng-container>
  <div class="it-row">
    <div class="it-col">
      <it-select
        [label]="'Category'"
        [options]="categories"
        [multi]="true"
        [(ngModel)]="value.categories"
        (ngModelChange)="onChange(value)"
      ></it-select>
    </div>
  </div>
</ng-container>
