<ng-container>
  <div class="it-row">
    <div class="it-col">
      <it-input
        [label]="'Button text'"
        [(ngModel)]="value.button_text"
        (ngModelChange)="onChange(value)"
      ></it-input>
    </div>
    <div class="it-col">
      <it-select
        [label]="'Menu'"
        [options]="menuOptions"
        [showSearch]="true"
        (searched)="getMenus(false, $event)"
        (loadMore)="getMenus(true, $event)"
        [emptyStateText]="'There are no menus.'"
        [notFoundText]="'There are no menus for selected filter.'"
        [(ngModel)]="value.menuId"
        (ngModelChange)="onChange(value)"
      ></it-select>
    </div>
  </div>
</ng-container>
