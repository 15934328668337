<div class="it-select-wrapper">
  <span class="it-select__label" (click)="hideList()" *ngIf="!hideLabel">
    <span>
      {{ label }}
      <sup class="required" *ngIf="label && required">*</sup>
    </span>
  </span>

  <div class="it-select"
     [ngClass]="{
     'it-select__multi': multi
    }">
  <label  class="it-select__control-display"
          [tabindex]="tabindex"
          (click)="toggle()"
          (focus)="showListToggle()"
          (keydown.tab)="hideList()"
          (keydown.shift.tab)="hideList()"
          [ngClass]="{
          'error': errors.length > 0,
          'disabled': disabled,
          'readOnly': readOnly,
          'open': showList,
          'required-full': requiredType === 'full',
          'small': size === 'small' }"
          #selectEl>
    <span class="it-select__placeholder" *ngIf="selected.length === 0">{{ placeholder }}</span>
    <span class="it-select__deselectAll" *ngIf="selected.length > 0 && showClearButton" (click)="clearOptions($event)"></span>
    <span class="it-select__multiple-value" *ngIf="multi">
        <span class="selected-option"
              *ngFor="let option of selected; let i = index">
          <span class="option-text">
            <span>{{option.name}}</span>
            <span [hidden]="selected.length === 1 || i === selected.length - 1">,&nbsp;</span>
          </span>
        </span>
    </span>
    <span class="it-select__value" *ngIf="!multi && selected.length > 0">
      {{selected[0].name}}
    </span>
  </label>
  <div
    class="it-select__list-container"
    [ngClass]="{ 'it-show': showList, 'clearing': clearing }"
    #listEl>
    <div class="it-close__list">
      <p class="label">{{ !label ? placeholder : label }}</p>
      <span class="close" *ngIf="!multi" (click)="hide()"></span>
    </div>
    <div class="it-loading__wrapper small select" *ngIf="loading">
      <div class="it-loading__rotate"></div>
    </div>
    <label class="search" *ngIf="showSearch">
      <input type="text"
             [placeholder]="searchPlaceholder"
             [(ngModel)]="searchTerm"
             (ngModelChange)="emitSearch()"
             (keydown.tab)="hideList()"
             (keyup.enter)="addClick('search')"
             #searchInput>
    </label>
    <div class="empty" *ngIf="!searchTerm && (!options || options.length <= 0)"><span>{{emptyStateText}}</span></div>
    <div class="not-found" *ngIf="searchTerm && (!options || options.length <= 0)">
      <p class="padding-bottom">{{notFoundText}}</p>
    </div>
    <div class="empty" *ngIf="(!options || options.length <= 0) && actionInfoText">
      <span>({{actionInfoText}})</span>
    </div>
    <ul class="it-select__options-container" itInfiniteScroll (scrolled)="emitLoadMore($event)">
      <li class="option"
          *ngIf="multi && showSelectAll"
          (click)="selectAll()"
          [ngClass]="{ 'checked': selectedAll }">
        <label class="option-content">
          <input type="checkbox" [checked]="selectedAll">
          <span class="option-text" (click)="selectAll()">Select all</span>
        </label>
      </li>
      <li class="option"
          *ngFor="let option of options; let i = index"
          (click)="optionSelect(option)"
          [ngClass]="{
            'checked': multi && value?.includes(option.id),
            'checked-dark': value === option.id,
            'event-disabled': optionClicked
             }">
        <label class="option-content" *ngIf="multi">
          <input type="checkbox" [checked]="value?.includes(option.id)">
          <span class="option-text" (click)="optionSelect(option)">
              {{option.name}}
          </span>
        </label>
        <div class="option-content" *ngIf="!multi">
          <span class="option-text">
              {{option.name}} <span *ngIf="showPhoneNumber && option.phone_number"> | {{option.phone_number}}</span>
            </span>
        </div>
      </li>
      <div class="add"
          *ngIf="showAddButton || addButtonText && (!options || options.length <= 0)">
        <button class="it-button primary it-icon plus"
                (click)="addClick()"
                [class.active]="!this.options || this.options.length <= 0">
          <span>{{addButtonText}}</span>
        </button>
      </div>
    </ul>
    <div class="responsive-actions" *ngIf="multi">
      <div class="button-clear" [class.show]="selected.length > 0" (click)="clearOptions($event); multiHideList()">
        <button>Clear</button>
      </div>
      <div class="button-confirm" [class.show]="multi" (click)="multiHideList()">
        <button>Confirm</button>
      </div>
    </div>
  </div>
  </div>

  <div *ngIf="errors.length > 0 && showError" class="it-select__error-box">
    <div *ngIf="errors.length === 1" class="it-select__error single">
      <span>{{ errorMessages[errors[0]] || errors[0] }}</span>
    </div>
    <div *ngIf="errors.length > 1" class="it-select__error multi">
      <p (click)="toggleErrors()">
        <span>You have {{ errors.length }} errors</span>
      </p>
      <div *ngIf="showErrors">
        <ul>
          <li *ngFor="let error of errors">
            {{ errorMessages[error] || error }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
