import { Component, forwardRef, Host, Inject, Input, OnInit, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../../apps/admin/src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'it-text-editor',
  templateUrl: 'text-editor.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ITTextEditorComponent),
    multi: true
  }]
})

export class ITTextEditorComponent implements OnInit, ControlValueAccessor {
  @Input() value: string = null;
  @Input() config: any = {};
  @Input() disabled: boolean = false;
  @Input() formControlName: string;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() errorMessages: any = {};
  @Input() organizationId: number = 9;
  @Input() type: string | 'classic' | 'inline' = 'classic';

  @Input()
    set tag(val) {
      if(val) {
        this.addTag(val);
      }
    }

  public editor: any; // = CKEDITOR;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  data: string;
  t;
  errors: any[] = [];
  showErrors: boolean;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.config = {};
    if (isPlatformBrowser(this.platformId)) {
      this.importCKEditor();
    }
  }

  ngOnInit() {
    if (this.required !== false) {
      this.required = true;
    }
    if (this.disabled !== false) {
      this.disabled = true;
    }
    if (!this.label) {
      this.label = '';
    }
  }

  async importCKEditor() {
    this.editor = (await import('./build/ckeditor')).default;
  }

  writeValue(value: any): void {
    setTimeout(() => {
      this.value = value ? value : '';
      if (this.value === '') {
        this.errors = [];
      }
    }, 500)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  displayError() {
    if (!this.formControlName) {
      return;
    }

    const formControl = this.controlContainer.control.get(this.formControlName);
    if (!formControl.touched) {
      return;
    }

    if (formControl.errors) {
      this.required = formControl.errors.required || false;
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
      if (this.value) {
        formControl.updateValueAndValidity();
        this.errors.splice(this.errors.indexOf('required'), 1);
      }
    } else {
      this.errors = [];
    }
  }

  onChangeValue(event) {
    if (this.onChange) {
      this.onChange(this.value);
      this.displayError();
    }
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  onReady(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader)=> {
      return new UploadAdapter(loader);
    };
    if(this.type === 'classic') {
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    }
  }

  addTag(tag) {
    this.t.model.change( writer => {
      writer.insertText( '{{' + tag + '}}' ,this.t.model.document.selection.getFirstPosition() );
    });
  }

}
class UploadAdapter {
  loader: any;

  constructor( loader ) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then( (file) => new Promise( ( resolve, reject ) => {
        if (localStorage.getItem('jwt')) {
          const uploader = new FileUploader({
            url: `${environment.apiRoot}file_manager/upload`,
            removeAfterUpload: true,
            autoUpload: true,
            authTokenHeader:  'Authorization',
            authToken: 'Bearer ' + localStorage.getItem('jwt'),
            headers: [],
            additionalParameter: {
              location: '/'
            }
          });
          uploader.addToQueue([file]);
          uploader.uploadAll();
          uploader.onCompleteItem = (file, response: any, status: any) => {
            if(status === 400) {
              const message = JSON.parse(response).message;
              reject(message)
            }else {
              const result = JSON.parse(response);
              resolve({ default: environment.apiRoot + 'storage/files' + result.data.images.large });
            }
          }
        } else {
          const myReader = new FileReader();
          myReader.onloadend = () => {
            resolve({ default: myReader.result });
          }
          myReader.readAsDataURL(file);
        }
      } ) );
  };
}
