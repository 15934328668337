import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';
import { Section } from '../../../../../../apps/admin/src/app/classes/section';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { BaseSectionComponent } from '../_base-section/base-section.component';
import { CustomSectionConfig } from './custom-section-config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'section[it-custom-section].it-section',
  templateUrl: './custom-section.component.html'
})
export class CustomSectionComponent extends BaseSectionComponent implements OnInit {
  @Input() preview: boolean = false;
  @Input() override section: Section;
  @Output() override sectionChanged = new EventEmitter();
  configuration: CustomSectionConfig = new CustomSectionConfig();


  visibility: string = '';
  @HostBinding(`class`) get t() {
    return this.visibility;
  };

  loadingContentId: string;
  selectedContentId: string;
  constructor(
    public override modalService: ModalService,
    public override sectionService: SectionService,
    public override toastService: ITToastService,
    public _sanitizer: DomSanitizer
  ) {
    super(
      modalService,
      sectionService,
      toastService
    );
  }

  override ngOnInit() {
    let data;
    
    if (typeof this.section.data === 'string') {
      data = JSON.parse(this.section.data);
    } else {
      data = JSON.parse(JSON.stringify(this.section.data));
    }

    this.configuration = new CustomSectionConfig(data);
    if(!this.preview) {
      this.visibility = this.section.visibility;
    }
    if (this.configuration.contents?.length === 0) {
      this.configuration.contents = [{
        id: 'content-100',
        text: '',
      }]
    }

    this.configuration.contents.forEach(element => {
      element.textPreview = this.videoTransform(element.text);
    });
  }

  selectContent(contentId) {
    if (!this.selectedContentId) {
      this.loadingContentId = contentId;
      this.selectedContentId = contentId;
      setTimeout(() => {
        this.loadingContentId = null;
      }, 800);
    }
  }

  save(contentId: string) {
    const savedContent = this.section.data?.contents?.find(c => c.id === contentId);
    const currentContent = this.configuration?.contents.find(c => c.id === contentId);

    if (currentContent?.text !== savedContent?.text) {
      // Dirty fix for the bug
      // text is empty and textPreview is not on the section that is not edited currently
      this.configuration.contents.forEach(content => {
        if (content.text === '' && content.textPreview?.changingThisBreaksApplicationSecurity !== '') {
          if (content.id !== contentId) {
            content.text = content.textPreview?.changingThisBreaksApplicationSecurity || '';
          }
        }
      });

      this.section.data = this.configuration;
      this.configuration = new CustomSectionConfig(this.section.data);
      
      this.configuration.contents?.forEach(element => {
        element.textPreview = this.videoTransform(element.text);
      });
      
      if (this.selectedContentId) {
        let sectionType: string;
        if (this.section.sectionable_type === 'App\\Models\\Page') {
          sectionType = 'pages';
        } else {
          sectionType = 'products';
        }

        this.sectionService.updateSection(sectionType, this.section.sectionable_id, this.section.id, this.section)
          .subscribe({
            next: () => {
              this.selectedContentId = null;
              this.toastService.show({
                type: 'success',
                message: 'Custom section has been successfully updated.'
              });
            },
            error: (error) => {
              this.modalService.error({
                errors: error
              })
            }
          })
      }
    } else {
      this.selectedContentId = null;
    }
  }

  videoTransform(htmlContent) {
    let found: any;

    if (!htmlContent) { return null; }

    // YouTube Long
    found = htmlContent.matchAll(/<oembed url=".+?watch\?v=(.+?)"><\/oembed>/g);
    for (const match of found) {
      const oembed = match[0];
      const videoCode = match[1];

      const embed = `
        <div class="embed-container youtube-embed">
          <iframe
            src="https://youtube.com/embed/${videoCode}"
            frameborder="0";
            scrolling="no";
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      `;

      htmlContent = htmlContent.replace(oembed, embed);
    }

    // YouTube Short
    found = htmlContent.matchAll(/<oembed url="https:\/\/youtu.be\/(.+?)"><\/oembed>/g);
    for (const match of found) {
      const oembed = match[0];
      const videoCode = match[1];

      const embed = `
        <div class="embed-container youtube-embed">
          <iframe
            src="https://youtube.com/embed/${videoCode}"
            frameborder="0";
            scrolling="no";
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      `;

      htmlContent = htmlContent.replace(oembed, embed);
    }

    return this._sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

}
