import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({selector: '[itImageHandler]'})

export class ITImageHandlerDirective implements AfterViewInit {
  @Input()
  set imagePath(val: any) {
    if (val) {
      this._imagePath = this.generateImagePath(val.name || val);

      if(this.baseElement?.src) {
        this.baseElement.src = this.apiRoot + 'storage/files' + this._imagePath;
      }      
    }
  }
  @Input() route: string = '';
  @Input() apiRoot: string;
  @Input() imageSize: string | 'large' | 'medium' | 'small' = 'large';

  _imagePath: string;
  baseElement: HTMLImageElement;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.baseElement = this.el.nativeElement;
    this.baseElement.src = this.apiRoot + 'storage/files' + this._imagePath;
  }

  generateImagePath(image) {
    const newImage = image.split('.');
    let imageSize;
    if(this.imageSize === 'large') {
      imageSize = '1920'
    }else if(this.imageSize === 'medium') {
      imageSize = '300'
    }else {
      imageSize = '150'
    }
    newImage[0] = this.route ? this.route + '_' + newImage[0] + '-thumbnail-' + imageSize : newImage[0] + '-thumbnail-' + imageSize;
    if(image.split('.').pop() === 'svg') {
      newImage[1] = 'svg';
    }else {
      newImage[1] = 'jpg';
    }
    return newImage.join('.');
  }

}
