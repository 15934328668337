<div class="it-section__edit-actions" *ngIf="preview">
  <div class="action" (click)="update()">
    <span class="it-icon settings"></span>
    <span>Configure section</span>
  </div>
  <div class="spacer"></div>
  <div class="action double">
    <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
    <span class="it-icon arrow-down" (click)="sectionChangeOrder('down')"></span>
  </div>
  <div class="action delete" (click)="remove()">
    <span class="it-icon delete"></span>
  </div>
</div>

<div class="it-section__content-wrapper {{ section.custom_class }} {{ section.visibility }}" [class.preview]="preview">
  <h1 *ngIf="section.title">{{section.title}}</h1>
    <div class="it-section__content promo" [itLoading]="showLoading" [backgroundColor]="'#fff'">
      <div class="it-section__item">
        <img [src]="section.data.imageUrl">
        <div [class]="'content ' + section.data.position">
          <div [innerHTML]="section.data.content"></div>
        </div>
      </div>
    </div>    

</div>
