import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionPanelComponent } from './section-panel.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ITInputModule } from '../../components/input/input.module';
import { ITSelectModule } from '../../components/select/select.module';
import { ProductListSectionModule } from '../../sections/product-list-section/product-list-section.module';
import { SliderSectionModule } from '../../sections/slider-section/slider-section.module';
import { AllProductsSectionModule } from '../../sections/all-products-section/all-products-section.module';
import { NewsletterSectionModule } from '../../sections/newsletter-section/newsletter-section.module';
import { CategoriesSectionModule } from '../../sections/categories-section/categories-section.module';
import { CustomSectionModule } from '../../sections/custom-section/custom-section.module';
import { PromoSectionModule } from '../../sections/promo-section/promo-section.module';

@NgModule({
  declarations: [
    SectionPanelComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ITInputModule,
    ITSelectModule,
    ProductListSectionModule,
    SliderSectionModule,
    AllProductsSectionModule,
    NewsletterSectionModule,
    CategoriesSectionModule,
    CustomSectionModule,
    PromoSectionModule,
  ]
})
export class SectionPanelModule { }
