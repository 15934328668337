import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationItem, NavigationItemType } from '../../classes/navigation-item';
import { environment } from './../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'it-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  apiRoot = environment.apiRoot;
  navigationItems: any;
  mainNavigation: any[] = [];
  selectedNavItems: any [] = [];

  currentUser;
  searchTerm: string;
  homePage: any;

  showSearchInput: boolean = false;
  showResponsiveSearchInput: boolean = false;
  showNavigation: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    const website = this.route.snapshot.data.website;
    const navigation = website.navigations.find(n => n.slug === 'main-navigation');
    this.navigationItems = navigation.navigation_items.sort((a, b) => { return a.order - b.order; });  
    this.getNavigation();

    this.userService.currentUser.subscribe({
      next: currentUser => this.currentUser = currentUser
    });
  }

  getNavigation() {
    this.mainNavigation = this.navigationItems.filter(navItem => !navItem.parent_id);
    this.mainNavigation.sort((a, b) => { return a.order - b.order; });
    this.navigationItems.find(navItem => {
      if (navItem.landing_page) { this.homePage = navItem; }
    });
    if (!this.homePage) { this.homePage = this.navigationItems[0]; }
    if (this.mainNavigation) {
      this.mainNavigation.map(mainNavItem => {
        mainNavItem.subitems = this.navigationItems.filter(navItem => mainNavItem.id === navItem.parent_id);
        mainNavItem.subitems.sort((a, b) => { return a.order - b.order; });
        if (mainNavItem.subitems) {
          mainNavItem.subitems.map(subitem => {
            subitem.subsubitems = this.navigationItems.filter(navItem => subitem.id === navItem.parent_id);
            subitem.subsubitems.sort((a, b) => { return a.order - b.order; });
          })
        }
      })
    }
  }

  toggleDropdown(item: NavigationItem, show: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      if (item.subitems) {
        if (item.subitems.every(subitem => subitem.subsubitems.length === 0)) {
          document.getElementById(item.title).classList.add('small');
        }
      }

      if (show) {
        document.getElementById(item.slug).classList.add('show');
        document.getElementById(item.title).classList.add('active');
      } else {
        document.getElementById(item.slug).classList.remove('show');
        document.getElementById(item.title).classList.remove('active');
      }
    }
  }

  toggleNavigation() {
    this.showNavigation = !this.showNavigation;

    if (isPlatformBrowser(this.platformId)) {
      document.getElementsByClassName('nav-sub__items')[0].classList.remove('show');
    }
  }

  toggleSubnavigation(event, id) {
    event.stopPropagation();

    if (isPlatformBrowser(this.platformId)) {
      if (document.getElementById(id).classList.contains('show')) {
        document.getElementById(id).classList.remove('show');
        if(this.selectedNavItems.includes(id)) {
          this.selectedNavItems = this.selectedNavItems.filter((value) => {return value !== id})
        }
      } else {
        document.getElementById(id).classList.add('show');
        if(!this.selectedNavItems.includes(id)) {
          this.selectedNavItems.push(id);
        }
      }
    }
  }

  showSearch() {
    this.showSearchInput = !this.showSearchInput;
  }

  showResponsiveSearch() {
    this.showResponsiveSearchInput = !this.showResponsiveSearchInput;
  }

  navigate(navigationItem: NavigationItem): void {
    if (isPlatformBrowser(this.platformId)) {
      // Close navigation
      document
        .querySelectorAll('.navigation-dropdown')
        .forEach(element => {
          element.classList.remove('show');
        });

      // Resolve route
      switch (navigationItem.type) {
        case NavigationItemType.ExternalURL:
          window.open(navigationItem.data.external_url, '_blank');
          break;
        case NavigationItemType.Page:
          this.router.navigate(['/page', navigationItem.entity.slug]);
          break;
        case NavigationItemType.PageCategory:
          this.router.navigate(['/page', 'category', navigationItem.entity.slug]);
          break;
        case NavigationItemType.Product:
          this.router.navigate(['/product', navigationItem.entity.slug]);
          break;
        case NavigationItemType.ProductCategory:
          this.router.navigate(['/product', 'category', navigationItem.entity.slug]);
          break;
        case NavigationItemType.ProductType:
          this.router.navigate(['/product', 'type', navigationItem.entity.slug]);
          break;
      
        default:
          break;
      }
    }
  }

  search() {
    this.router.navigate(['/product', 'search'], { queryParams: { search_term: this.searchTerm } });
    this.showSearchInput = false;
    this.showResponsiveSearchInput = false;
    this.showNavigation = false;
    this.searchTerm = '';
  }

  logout() {
    this.authService.logout()
      .subscribe({
        next: () => {
          this.router.navigate(['/login']);
        },
        error: () => {}
      });
  }

}
