import { Directive, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Directive({selector: '[itOffClick]'})

export class ITOffClickDirective {
  @Input() disabled: boolean = false;
  @Input() withBackDrop: boolean = false;
  @Output() offClick: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('document:click', ['$event'])
  click(event) {
    this.clickHandler(event.target, event);
  }

  private clickHandler(currentElement: HTMLElement, event: MouseEvent): void {
    if (this.withBackDrop) {
      const backdropElements = document.body.getElementsByClassName('it-offclick__backdrop');
      if (backdropElements.length === 0) {
        const backdrop = document.createElement('div');
        backdrop.classList.add('it-offclick__backdrop');
        document.body.appendChild(backdrop);
      } else {
        for (let i = 0; i < backdropElements.length; i++) {
          if (currentElement === backdropElements[i]) {
            backdropElements[i].remove();
            this.offClick.emit(event);
          }
        }
      }
    } else {
      if (!this.disabled) {
        if (currentElement.classList.contains('ck-button') || currentElement.classList.contains('ck-icon')) {
          return;
        }
        if (currentElement === document.body || currentElement == null) {
          this.offClick.emit(event);
          return;
        }
        if (currentElement === this.el.nativeElement) {
          return;
        }
        if (currentElement.parentElement) {
          this.clickHandler(currentElement.parentElement, event);
        }
      }
    }
  }

}
