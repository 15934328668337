import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private apiService: ApiService
  ) { }

  getProducts(params?: any) {
    return this.apiService.get(`products`, params);
  }
  getProduct(productId: string, params?: any) {
    return this.apiService.get(`products/${productId}`, params);
  }
  createProduct(params: any) {
    return this.apiService.post(`products`, params);
  }
  updateProduct(productId: string, params?: any) {
    return this.apiService.put(`products/${productId}`, params);
  }
  deleteProduct(productId: string) {
    return this.apiService.delete(`products/${productId}`);
  }
  duplicateProduct(productId: string, params?: any) {
    return this.apiService.post(`products/${productId}/clone`, params);
  }
  filterProducts(params?: any) {
    return this.apiService.get(`products/filter`, params);
  }

  //Categories
  getProductCategories(params?: any) {
    return this.apiService.get(`product_categories`, params);
  }
  getProductCategory(productCategoryId: string, params?: any) {
    return this.apiService.get(`product_categories/${productCategoryId}`, params);
  }
  createProductCategory(params: any) {
    return this.apiService.post(`product_categories`, params);
  }
  updateProductCategory(productCategoryId: string, params?: any) {
    return this.apiService.put(`product_categories/${productCategoryId}`, params);
  }
  deleteProductCategory(productCategoryId: string) {
    return this.apiService.delete(`product_categories/${productCategoryId}`);
  }

  //Types
  getProductTypes(params?: any) {
    return this.apiService.get(`product_types`, params);
  }
  getProductType(productTypeId: string, params?: any) {
    return this.apiService.get(`product_types/${productTypeId}`, params);
  }
  createProductType(params: any) {
    return this.apiService.post(`product_types`, params);
  }
  updateProductType(productTypeId: string, params?: any) {
    return this.apiService.put(`product_types/${productTypeId}`, params);
  }
  deleteProductType(productTypeId: string) {
    return this.apiService.delete(`product_types/${productTypeId}`);
  }
  duplicateType(typeId: string, params?: any) {
    return this.apiService.post(`product_types/${typeId}/clone`, params);
  }

  //Groups & Properties
  getProductGroups(productTypeId: string, params?: any) {
    return this.apiService.get(`product_types/${productTypeId}/product_property_groups`, params);
  }
  getProductGroup(productTypeId: string, productPropertyGroupId: string, params?: any) {
    return this.apiService.get(`product_types/${productTypeId}/product_property_groups/${productPropertyGroupId}`, params);
  }
  createProductGroup(productTypeId: string, params: any) {
    return this.apiService.post(`product_types/${productTypeId}/product_property_groups`, params);
  }
  updateProductGroup(productTypeId: string, productPropertyGroupId: string, params?: any) {
    return this.apiService.put(`product_types/${productTypeId}/product_property_groups/${productPropertyGroupId}`, params);
  }
  deleteProductGroup(productTypeId: string, productPropertyGroupId: string) {
    return this.apiService.delete(`product_types/${productTypeId}/product_property_groups/${productPropertyGroupId}`);
  }
  changeOrderProductGroup(productTypeId: string, productPropertyGroupId: string, params?: any) {
    return this.apiService.put(`product_types/${productTypeId}/product_property_groups/${productPropertyGroupId}/order`, params);
  }

  getProductGroupProperties(productPropertyGroupId: string, params?: any) {
    return this.apiService.get(`product_property_groups/${productPropertyGroupId}/product_properties`, params);
  }
  getProductGroupProperty(productPropertyGroupId: string, productPropertyId: string, params?: any) {
    return this.apiService.get(`product_property_groups/${productPropertyGroupId}/product_properties/${productPropertyId}`, params);
  }
  createProductGroupProperty(productPropertyGroupId: string, params: any) {
    return this.apiService.post(`product_property_groups/${productPropertyGroupId}/product_properties`, params);
  }
  updateProductGroupProperty(productTypeId: string, productPropertyGroupId: string, params?: any) {
    return this.apiService.put(`product_property_groups/${productTypeId}/product_properties/${productPropertyGroupId}`, params);
  }
  deleteProductGroupProperty(productPropertyGroupId: string, productTypeId: string) {
    return this.apiService.delete(`product_property_groups/${productPropertyGroupId}/product_properties/${productTypeId}`);
  }
  changeOrderProductGroupProperty(productPropertyGroupId: string, productPropertyId: string, params?: any) {
    return this.apiService.put(`product_property_groups/${productPropertyGroupId}/product_properties/${productPropertyId}/order`, params);
  }
  changeOrderProductGroupPropertyOption(productPropertyId: string, productPropertyOptionId: string, params?: any) {
    return this.apiService.put(`product_properties/${productPropertyId}/product_property_options/${productPropertyOptionId}/order`, params);
  }


  // Accessory & Relate
  getProductAccessories(productId: string, params?: any) {
    return this.apiService.get(`products/${productId}/accessories`, params);
  }
  addProductAccessory(productId: string, params?: any) {
    return this.apiService.post(`products/${productId}/accessories`, params);
  }
  deleteProductAccessory(productId: string, accessoryId: string, params?: any) {
    return this.apiService.delete(`products/${productId}/accessories/${accessoryId}`, params);
  }

  getProductRelated(productId: string, params?: any) {
    return this.apiService.get(`products/${productId}/related`, params);
  }
  addProductRelated(productId: string, params?: any) {
    return this.apiService.post(`products/${productId}/related`, params);
  }
  deleteProductRelated(productId: string, relatedId: string, params?: any) {
    return this.apiService.delete(`products/${productId}/related/${relatedId}`, params);
  }

  syncProducts() {
    return this.apiService.sync();
  }
}
