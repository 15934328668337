import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITSlidesComponent } from './slides.component';
import { ITSlideComponent } from './slide.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ITImageHandlerModule } from '../../directives/image-handler/image-handler.module';



@NgModule({
  declarations: [
    ITSlidesComponent,
    ITSlideComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    ITImageHandlerModule
  ],
  exports: [
    ITSlidesComponent,
    ITSlideComponent
  ]
})
export class ITSliderModule { }
