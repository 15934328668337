<div class="it-section__edit-actions" *ngIf="preview">
  <div class="action" (click)="update()">
    <span class="it-icon settings"></span>
    <span>Configure section</span>
  </div>
  <div class="spacer"></div>
  <div class="action double">
    <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
    <span class="it-icon arrow-down" (click)="sectionChangeOrder('down')"></span>
  </div>
  <div class="action delete" (click)="remove()">
    <span class="it-icon delete"></span>
  </div>
</div>
<div class="it-section__content all-products">
  <h1 *ngIf="section.title">{{section.title}}</h1>
  <div class="it-section__item">
    <it-button [icon]="'more'" [iconPositionRight]="true" (click)="toggleNavigation()">{{configuration.button_text}}</it-button>
  </div>
</div>

<nav class="it-section-navigation__mobile">
  <div class="navigation-wrapper" [class.show]="showNavigation">
    <div class="section-nav__header">
      <div class="logo"></div>
      <div class="ns-icon menu-close" (click)="closeNav()"></div>
    </div>
    <ul>
      <li class="nav-item" *ngFor="let item of navigationItems" (click)="item?.subitems.length > 0 ? toggleSubnavigation($event, item?.id) : ''">
        <span class="title" [class.active]="router.url.includes(item.slug)" (click)="item?.subitems?.length > 0 ? '' : navigate(item); ">{{item.title}} <span class="it-icon arrow-right"  *ngIf="item?.subitems.length > 0"></span></span>
        <ul class="nav-sub__items" *ngIf="item?.subitems.length > 0" [id]="item?.id + '-section'">
          <li class="go-back" (click)="toggleSubnavigation($event, item?.id)"><span class="ns-icon arrow-left"></span>Nazad</li>
          <li class="caption" (click)="$event.stopPropagation()">{{item.title}}</li>
          <li class="nav-item sub" *ngFor="let subitem of item.subitems" (click)="subitem?.subsubitems.length > 0 ? toggleSubnavigation($event, subitem.id) : ''">
            <span class="title"  (click)="subitem?.subsubitems?.length > 0 ? '' : navigate(subitem);">{{subitem.title}}<span class="it-icon" *ngIf="subitem?.subsubitems.length > 0" [class.arrow-down]="selectedNavItems.includes(subitem?.id) ? false : true" [class.arrow-up]="selectedNavItems.includes(subitem?.id) ? true : false"></span></span>
            <ul class="nav-sub-sub__items" *ngIf="subitem?.subsubitems.length > 0" [id]="subitem?.id + '-section'">
              <li *ngFor="let subsubitem of subitem.subsubitems" (click)="navigate(subsubitem);">{{subsubitem?.title}}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
