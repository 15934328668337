import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITToastComponent } from './toast.component';
import { ITToastService } from './toast.service';



@NgModule({
  declarations: [
    ITToastComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    ITToastService
  ],
  exports: [
    ITToastComponent
  ]
})
export class ITToastModule { }
