<div class="it-accordion" [ngClass]="{ 'active': active }">
  <header class="it-accordion__header" (click)="_activate()">
    <h3 class="it-accordion__title">{{ title }}</h3>
    <div class="it-accordion__actions">
      <div class="it-accordion__info" *ngIf="info">
        <span>{{info}}</span>
      </div>
      <div class="it-accordion__action">
        <button class="it-icon" [ngClass]="{'arrow-up': active, 'arrow-down': !active}">
        </button>
      </div>
    </div>
  </header>
  <section class="it-accordion__body" [hidden]="!active">
    <ng-content></ng-content>
  </section>
</div>
