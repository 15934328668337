import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CompareService {
  private compareProductsSubject = new BehaviorSubject<any[]>(null);
  compareProducts = this.compareProductsSubject.asObservable();

  constructor(
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  getCompareProducts() {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem('compare-products'));
    } else {
      return null;
    }
  }

  addCompareProduct(product: any) {
    if (isPlatformBrowser(this.platformId)) {
      const compareProductsFromStorage = JSON.parse(localStorage.getItem('compare-products'));

      if(compareProductsFromStorage && compareProductsFromStorage.find(element => element.id === product.id)) { return; }

      if(compareProductsFromStorage) {
        compareProductsFromStorage.push(product);
        localStorage.setItem('compare-products', JSON.stringify(compareProductsFromStorage));
      }else {
        localStorage.setItem('compare-products', JSON.stringify([product]));
      }
      this.compareProductsSubject.next(JSON.parse(localStorage.getItem('compare-products')))
    }
  }

  removeCompareProduct(productId: string) {
    if (isPlatformBrowser(this.platformId)) {
      const compareProductsFromStorage = JSON.parse(localStorage.getItem('compare-products'));
      const selectedProduct = compareProductsFromStorage.find(element => element.id === productId);
      if(selectedProduct) {
        compareProductsFromStorage.splice(compareProductsFromStorage.indexOf(selectedProduct), 1);
        localStorage.setItem('compare-products', JSON.stringify(compareProductsFromStorage));
        this.compareProductsSubject.next(compareProductsFromStorage);
      }
    }
  }

  clearCompare() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('compare-products');
      this.compareProductsSubject.next(null);
    }
  }

}
