import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../../apps/admin/src/environments/environment';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';
import { Category } from '../../../../../../apps/admin/src/app/classes/category';
import { Section } from '../../../../../../apps/admin/src/app/classes/section';
import { ProductService } from '../../../../../../apps/admin/src/app/services/product.service';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { BaseSectionComponent } from '../_base-section/base-section.component';
import { CategoriesSectionConfig } from './categories-section-config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'section[it-categories-section].it-section',
  templateUrl: './categories-section.component.html'
})
export class CategoriesSectionComponent extends BaseSectionComponent implements OnInit {
  @Input() preview: boolean = false;
  @Input() override section: Section;
  @Output() override sectionChanged = new EventEmitter();
  configuration: CategoriesSectionConfig = new CategoriesSectionConfig();

  slidesBreakpoints = {
    320: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 0
    }
  };

  visibility: string = '';
  @HostBinding(`class`) get t() {
    return this.visibility;
  };

  categories: Category[];
  apiRoot = environment.apiRoot;

  showLoading: boolean = false;
  constructor(
    public override modalService: ModalService,
    public override sectionService: SectionService,
    public override toastService: ITToastService,
    private productService: ProductService,
  ) {
    super(
      modalService,
      sectionService,
      toastService
    );
  }

  override ngOnInit() {
    this.configuration = new CategoriesSectionConfig(this.section.data);
    if (!this.preview) {
      this.visibility = this.section.visibility;
    }
    this.getCategories();
  }

  getCategories() {
    const params: any = {}
    params['ids[]'] = this.configuration.categories;
    this.productService.getProductCategories(params)
      .subscribe({
        next: (response) => {
          this.categories = response.data;
        },
        error: (error) => {
          this.modalService.error({
            errors: error
          })
        }
      });
  }

}
