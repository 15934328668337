import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITTextEditorComponent } from './text-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ITTextEditorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CKEditorModule
  ],
  exports: [
    ITTextEditorComponent
  ]
})
export class ITTextEditorModule { }
