<div class="it-modal medium" [class.large]="sectionType === 'slider'">
  <div class="it-modal__header">
    <h2 class="it-modal__title">Configure section</h2>
    <span class="it-modal__close" (click)="close()"></span>
  </div>
  <div class="it-modal__body">
    <form class="it-row" [formGroup]="sectionForm">
      <div class="it-col mt-0">
        <it-input
          [label]="'Title'"
          formControlName="title"
        ></it-input>
      </div>
      <div class="it-col">
        <it-select
          [label]="'Visibility'"
          [options]="visibilityOptions"
          [showClearButton]="false"
          formControlName="visibility"
        ></it-select>
      </div>
      <div class="it-col">
        <it-input
          [label]="'Custom class'"
          formControlName="custom_class"
        ></it-input>
      </div>
      <ng-container [ngSwitch]="sectionType">
        <div class="it-col mt-0" *ngSwitchCase="'custom'">
          <it-custom-section-configuration formControlName="data"></it-custom-section-configuration>
        </div>
        <div class="it-col mt-0" *ngSwitchCase="'all-products'">
          <it-all-products-section-configuration formControlName="data"></it-all-products-section-configuration>
        </div>
        <div class="it-col mt-0" *ngSwitchCase="'slider'">
          <it-slider-section-configuration formControlName="data"></it-slider-section-configuration>
        </div>
        <div class="it-col mt-0" *ngSwitchCase="'product-list'">
          <it-product-list-section-configuration formControlName="data"></it-product-list-section-configuration>
        </div>
        <div class="it-col mt-0" *ngSwitchCase="'newsletter'">
          <it-newsletter-section-configuration formControlName="data"></it-newsletter-section-configuration>
        </div>
        <div class="it-col mt-0" *ngSwitchCase="'category'">
          <it-categories-section-configuration formControlName="data"></it-categories-section-configuration>
        </div>
        <div class="it-col mt-0" *ngSwitchCase="'promo'">
          <it-promo-section-configuration formControlName="data"></it-promo-section-configuration>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="it-modal__footer">
    <button class="cancel" (click)="close()">Cancel</button>
    <button class="primary" (click)="save()">Save</button>
  </div>
</div>
