import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSectionComponent } from './custom-section.component';
import { CustomSectionConfigurationComponent } from './custom-section-configuration.component';
import { FormsModule } from '@angular/forms';
import { ITInputModule } from '../../components/input/input.module';
import { ITTextEditorModule } from '../../components/text-editor/text-editor.module';
import { ITOffClickModule } from '../../components/off-click/off-click.module';

@NgModule({
  declarations: [
    CustomSectionComponent,
    CustomSectionConfigurationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ITInputModule,
    ITTextEditorModule,
    ITOffClickModule
  ],
  exports: [
    CustomSectionComponent,
    CustomSectionConfigurationComponent
  ]
})
export class CustomSectionModule { }
