import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'it-tab',
  templateUrl: './tab.component.html'
})
export class ITTabComponent implements OnInit {
  @Input() title: string;
  @Input() active: boolean = false;
  @Input() id: string;
  ngOnInit() {
    if (this.active !== false) { this.active = true; }
  }
}
