import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../../apps/admin/src/environments/environment';
import { ModalService } from '../../components/modal/modal.service';
import { ITToastService } from '../../components/toast/toast.service';
import { Section } from '../../../../../../apps/admin/src/app/classes/section';
import { SectionService } from '../../../../../../apps/admin/src/app/services/section.service';
import { BaseSectionComponent } from '../_base-section/base-section.component';
import { PromoSectionConfig } from './promo-section-config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'section[it-promo-section].it-section',
  templateUrl: './promo-section.component.html'
})
export class PromoSectionComponent extends BaseSectionComponent implements OnInit {
  @Input() preview: boolean = false;
  @Input() override section: Section;
  @Output() override sectionChanged = new EventEmitter();
  configuration: PromoSectionConfig = new PromoSectionConfig();

  visibility: string = '';
  @HostBinding(`class`) get t(){
    return this.visibility;
  };

  apiRoot = environment.apiRoot;

  showLoading: boolean = false;
  constructor(
    public override modalService: ModalService,
    public override sectionService: SectionService,
    public override toastService: ITToastService
  ) {
    super(
      modalService,
      sectionService,
      toastService
    );
  }

  override ngOnInit() {
    this.configuration = new PromoSectionConfig(this.section.data);
    
    if(!this.preview) {
      this.visibility = this.section.visibility;
    }
  }

  openLink(link, newTab = true) {
    if(!this.preview) {
      window.open(link, newTab ? '_blank' : '_self');
    }
  }

}
