<div [ngSwitch]="type" class="it-input__wrapper">
  <label *ngSwitchCase="'textarea'" class="it-input textarea">
        <span class="it-input__label">
            <span>
              {{ label }}
              <sup class="required" *ngIf="label && required">*</sup>
            </span>
        </span>
    <textarea
      (input)= "onChangeValue()"
      (blur)= "displayError()"

      [(ngModel)]="value"
      [rows]= "rows"
      [cols]= "cols"
      [readOnly]="readOnly"
      [placeholder]="placeholder"
      [style.resize]="resize === 'both' ? 'auto' : resize === 'vertical' ? 'vertical' : resize === 'horizontal' ? 'horizontal' : 'none'"
      [ngClass]="{
                'required': requiredType === 'full',
                'error': errors.length > 0
                }">
        </textarea>
  </label>

  <label *ngSwitchCase="'search'" class="it-input search" [ngClass]="{'mobile': platform === 'mobile', 'website': platform === 'website'}">
        <span
          *ngIf="showSearchIcon"
          class="search-icon"
          (click)="emitSearchTerm()"
          [ngClass]="{
                    'filled': value,
                    'disabled': disabled,
                    'large' : size === 'large'
                }"
        ></span>
    <span class="clearSearchTerm" *ngIf="value && showClearButton" (click)="clearSearchTerm()"></span>
    <input
      #inputSearch
      (input)= "onChangeValue()"
      (keyup.enter)="emitSearchTerm()"

      [placeholder]="placeholder || label"
      [(ngModel)]="value"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [ngClass]="{
                    'filled': value,
                    'large' : size === 'large'
                }"
      enterkeyhint="search"
    >
  </label>

  <label *ngSwitchDefault class="it-input">
    <span class="it-input__label" *ngIf="showLabel">
        <span>
            {{ label }}
          <sup class="required" *ngIf="label && required">*</sup>
        </span>
    </span>
    <span class="it-input__password-toggle"
          *ngIf="showPassword"
          (click)="togglePassword()"
          [ngClass]="hidePassword ? 'hide' : 'show'"
    ></span>
    <span
      class="input-button"
      *ngIf="buttonText || buttonIcon"
      (click)="valueEmit()"
      [style.font-size]="buttonFontSize + 'px'"
      [ngClass]="
        {
          'disabled': disabled,
          'large' : size === 'large'
        }">
    {{buttonText}}
    <span class="it-icon {{buttonIcon}}"></span>
  </span>
    <input
      #input
      (input)="onChangeValue()"
      (blur)="displayError();blur.emit($event)"
      (focus)="focus.emit($event)"

      [(ngModel)]="value"
      [placeholder]="placeholder"
      [type]= "type"
      [autocomplete]= "autoComplete"
      [max]="max"
      [min]="min"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [tabindex]="tabindex"
      [ngClass]="{
                'required': requiredType === 'full',
                'error': errors.length > 0
                }">
  </label>

  <div class="it-input__info-text" *ngIf="info">
    <span>{{ info }}</span>
  </div>

  <div *ngIf="errors.length > 0 && showError" class="it-input__error-box">
    <div *ngIf="errors.length === 1" class="it-input__error single">
      <span>{{ errorMessages[errors[0]] || errors[0] }}</span>
    </div>
    <div *ngIf="errors.length > 1" class="it-input__error multi">
      <span (click)="toggleErrors()">You have {{ errors.length }} errors</span>
      <div *ngIf="showErrors">
        <ul>
          <li *ngFor="let error of errors">
            {{ errorMessages[error] || error }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

