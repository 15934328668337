import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesSectionComponent } from './categories-section.component';
import { CategoriesSectionConfigurationComponent } from './categories-section-configuration.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ITSelectModule } from '../../components/select/select.module';
import { ITLoadingModule } from '../../components/loading/loading.module';
import { ITButtonModule } from '../../components/button/button.module';
import { ITImageHandlerModule } from '../../directives/image-handler/image-handler.module';
import { ITSliderModule } from '../../components/carousel/slider.module';

@NgModule({
  declarations: [
    CategoriesSectionComponent,
    CategoriesSectionConfigurationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ITSelectModule,
    ITLoadingModule,
    ITButtonModule,
    ITSliderModule,
    ITImageHandlerModule
  ],
  exports: [
    CategoriesSectionComponent,
    CategoriesSectionConfigurationComponent
  ]
})
export class CategoriesSectionModule { }
