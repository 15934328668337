<div class="it-section__edit-actions" *ngIf="preview">
  <div class="action" (click)="update()">
    <span class="it-icon settings"></span>
    <span>Configure section</span>
  </div>
  <div class="spacer"></div>
  <div class="action double">
    <span class="it-icon arrow-up" (click)="sectionChangeOrder('up')"></span>
    <span class="it-icon arrow-down" (click)="sectionChangeOrder('down')"></span>
  </div>
  <div class="action delete" (click)="remove()">
    <span class="it-icon delete"></span>
  </div>
</div>

<div class="it-section__content-wrapper {{ section.custom_class }} {{ section.visibility }}">
  <h1 *ngIf="section.title">{{section.title}}</h1>
  <ng-container *ngIf="configuration?.columns < 2">
    <div class="it-section__content slides" [itLoading]="showLoading" [backgroundColor]="'#fff'">
      <div class="it-section__item" *ngIf="slides && slides.length > 0">
        <it-slides [loop]="true" [pagination]="true">
          <it-slide *ngFor="let slide of slides" [fileRoot]="apiRoot" [image]="slide.imageUrl">
            <div class="text-content" [class.dark]="slide.dark">
              <h1>{{ slide.title }}</h1>
              <p>{{ slide.description }}</p>
              <it-button (click)="openLink(slide.linkUrl)">Saznajte više</it-button>
            </div>
          </it-slide>
        </it-slides>
      </div>
    </div>    
  </ng-container>
  <ng-container *ngIf="configuration?.columns > 1">
    <div class="it-section__content slides" [itLoading]="showLoading">
      <div class="it-section__item" *ngIf="slides && slides.length > 0">
        <it-slides 
          [loop]="true" 
          [pagination]="false" 
          [slidesPerView]="1"
          [slidesBreakpoints]="{ '800': { slidesPerView: configuration.columns } }"
        >
          <ng-container *ngFor="let slide of slides">
            <it-slide 
              [fileRoot]="apiRoot" 
              [image]="slide.imageUrl" 
              (click)="openLink(slide.linkUrl, false)"
            >
              <div class="text-content">
                <h2>{{ slide.title }}</h2>
                <p>{{ slide.description }}</p>
              </div>
            </it-slide>
          </ng-container>
        </it-slides>
      </div>
    </div>    
  </ng-container>

</div>
